var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态", clearable: "" },
                      model: {
                        value: _vm.query.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status",
                      },
                    },
                    _vm._l(_vm.searchFrom.statusList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.permission.selectStoreList
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择门店",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.query.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.query, "storeId", $$v)
                            },
                            expression: "query.storeId",
                          },
                        },
                        _vm._l(_vm.searchFrom.storeList, function (item) {
                          return _c("el-option", {
                            key: item.storeId,
                            attrs: {
                              label: item.storeName,
                              value: item.storeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "请选择开始时间",
                      "picker-options": _vm.searchPickerOptionsStart,
                    },
                    model: {
                      value: _vm.query.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "startTime", $$v)
                      },
                      expression: "query.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "结束时间",
                      "picker-options": _vm.searchPickerOptionsEnd,
                    },
                    model: {
                      value: _vm.query.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "endTime", $$v)
                      },
                      expression: "query.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "id",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "用户名",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "门店名称",
                  align: "center",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "beauticianName",
                  label: "美容师名称",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  align: "center",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 5 &&
                        scope.row.relatedSolutionsId &&
                        scope.row.relatedSolutionsId > 0
                          ? _c("span", [_vm._v("解决方案已关联")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getStateName")(
                                    scope.row.status,
                                    _vm.searchFrom.statusList
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "beforeLeaveStatus",
                  label: "离店前状态",
                  align: "center",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.beforeLeaveStatus == 5 &&
                        scope.row.relatedSolutionsId &&
                        scope.row.relatedSolutionsId > 0
                          ? _c("span", [_vm._v("解决方案已关联")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getStateName")(
                                    scope.row.beforeLeaveStatus,
                                    _vm.searchFrom.beforeLeaveStatusList
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isCase",
                  label: "是否为案例",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isCase
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "备注",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "expireReason",
                  label: "失效原因",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "entryTime",
                  label: "入店时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.entryTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reportTime",
                  label: "报告生成时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.reportTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "symptomDescriptionTime",
                  label: "症状描述提交时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                scope.row.symptomDescriptionTime
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "prescriptionFormTime",
                  label: "处方单开具时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                scope.row.prescriptionFormTime
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "leaveTime",
                  label: "出店时间",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.leaveTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "110px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.updateSeekBeautyLogNewInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    scope.row.isCase == 1
                                      ? "取消案例"
                                      : "设置为案例",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title:
                                        scope.row.isCase == 1
                                          ? "您确定要取消案例吗？"
                                          : "您确定要设置为案例吗？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.updateSeekBeautyLogNewInfoHandle(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      class:
                                        scope.row.isCase == 1
                                          ? "danger"
                                          : "success",
                                      attrs: {
                                        slot: "reference",
                                        icon:
                                          scope.row.isCase == 1
                                            ? "el-icon-open"
                                            : "el-icon-turn-off",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.getSeekBeautyLogDetail
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "日志详情",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-s-claim",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.solutionHandle(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.getReportDetail &&
                        scope.row.reportTime > 0
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "报告详情",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-tickets",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getReportDetailHandle(
                                        scope.row
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "查看解决方案",
            visible: _vm.dialogLookSolutionVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookSolutionVisible = $event
            },
          },
        },
        [
          _vm.dialogLookSolutionVisible
            ? _c("Solution", { attrs: { id: _vm.currentID } })
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogLookReportDetailVisible
        ? _c("testReportDialog", {
            attrs: {
              is_dialog_visible: _vm.dialogLookReportDetailVisible,
              seek_beauty_id: _vm.currentID,
              user_id: _vm.currentUserID,
            },
            on: {
              "update:is_dialog_visible": function ($event) {
                _vm.dialogLookReportDetailVisible = $event
              },
              changeContrastTimeSuccess: _vm.changeContrastTimeSuccess,
            },
          })
        : _vm._e(),
      _vm.isTimeDialogVisible
        ? _c("contrastTimeDialog", {
            attrs: { is_time_dialog_visible: _vm.isTimeDialogVisible },
            on: {
              "update:is_time_dialog_visible": function ($event) {
                _vm.isTimeDialogVisible = $event
              },
              changeContrastTimeSuccess: _vm.changeContrastTimeSuccess,
            },
          })
        : _vm._e(),
      _vm.isDialogContrastReportVisible
        ? _c("contrastReportDialog", {
            attrs: {
              is_contrast_dialog_visible: _vm.isDialogContrastReportVisible,
              contrast_entry_record_info: _vm.contrastEntryRecordInfo,
            },
            on: {
              "update:is_contrast_dialog_visible": function ($event) {
                _vm.isDialogContrastReportVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }