var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "change-time-dialog-wrap-part-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "change-time-dialog-part-box",
            width: "800px",
            title: "请选择",
            center: "",
            visible: _vm.is_time_dialog_visible,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
          },
          on: { close: _vm.closeChangeTimeDialog },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-1 dialog-content-part-wrap-box fr-b-s text-no-select",
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-chang-date-item-box fl-s-c",
                  class: { active: _vm.addOrEditModel.startEntryRecordId },
                },
                [
                  !_vm.addOrEditModel.startEntryRecordId
                    ? _c("div", {
                        staticClass: "w-1 date-item-not-select-picker-part-box",
                      })
                    : _c(
                        "div",
                        { staticClass: "w-1 date-item-selected-box fl-s-c" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-1 chang-date-popover-btn fr-s-c text-16 color-main",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$util.formatTime(
                                    _vm.addOrEditModel.startReportTime,
                                    "yyyy-mm-dd hh:M"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "w-1 fr-c date-selected-image-box" },
                            [
                              _c("el-image", {
                                staticClass: "iamges-origin",
                                attrs: {
                                  fit: "fit",
                                  src: _vm.addOrEditModel.startImgDaylight,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-chang-date-item-box fl-s-c",
                  class: { active: _vm.addOrEditModel.endEntryRecordId },
                },
                [
                  !_vm.addOrEditModel.endEntryRecordId
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-1 date-item-not-select-picker-part-box",
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "dialog-chang-date-popover",
                                placement: "bottom",
                                width: "300",
                                trigger: "click",
                              },
                              model: {
                                value: _vm.isChangEndDatePopper,
                                callback: function ($$v) {
                                  _vm.isChangEndDatePopper = $$v
                                },
                                expression: "isChangEndDatePopper",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1 h-1 fr-s-c chang-date-popover-btn",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("img", {
                                    staticClass: "iamges-date",
                                    attrs: { src: _vm.contrast_pop_date },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-16 color-32" },
                                    [_vm._v("结束日期")]
                                  ),
                                ]
                              ),
                              _c(
                                "el-scrollbar",
                                {
                                  staticClass:
                                    "w-1 h-1 chang-date-popover-scrollbar fr-s-s",
                                },
                                _vm._l(
                                  _vm.endEntryRecordList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "w-1 fr-s-c chang-date-popover-item",
                                        class: {
                                          selected:
                                            _vm.addOrEditModel
                                              .endEntryRecordId == item.id,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickSelectEndTimeEntryRecord(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$util.formatTime(
                                                item.reportTime,
                                                "yyyy-mm-dd hh:M"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "w-1 date-item-selected-box fl-s-c" },
                        [
                          _c(
                            "div",
                            { staticClass: "w-1" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    "popper-class": "dialog-chang-date-popover",
                                    placement: "bottom",
                                    width: "300",
                                    trigger: "click",
                                  },
                                  model: {
                                    value: _vm.isChangEndDatePopper,
                                    callback: function ($$v) {
                                      _vm.isChangEndDatePopper = $$v
                                    },
                                    expression: "isChangEndDatePopper",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1 h-1 fr-s-c chang-date-popover-btn",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "iamges-date",
                                        attrs: { src: _vm.contrast_pop_date },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "text-16 color-main" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$util.formatTime(
                                                _vm.addOrEditModel
                                                  .endReportTime,
                                                "yyyy-mm-dd hh:M"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-scrollbar",
                                    {
                                      staticClass:
                                        "w-1 h-1 chang-date-popover-scrollbar fr-s-s",
                                    },
                                    _vm._l(
                                      _vm.endEntryRecordList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "w-1 fr-s-c chang-date-popover-item",
                                            class: {
                                              selected:
                                                _vm.addOrEditModel
                                                  .endEntryRecordId == item.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickSelectEndTimeEntryRecord(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$util.formatTime(
                                                    item.reportTime,
                                                    "yyyy-mm-dd hh:M"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-1 fr-c date-selected-image-box" },
                            [
                              _c("el-image", {
                                staticClass: "iamges-origin",
                                attrs: {
                                  fit: "fit",
                                  src: _vm.addOrEditModel.endImgDaylight,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer fr-e-c",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", round: "" },
                  on: { click: _vm.closeChangeTimeDialog },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.clickConfirmChangeTime },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }