var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "test-report-dialog-wrap-part-box",
      class: { "bg-ff-dialog": _vm.isShowImgWhiteBg },
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "test-report-dialog-part-box",
            width: "calc(100% - 20px)",
            visible: _vm.is_contrast_dialog_visible,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
          },
          on: { close: _vm.closeTestReportDialog },
        },
        [
          _vm.isShowPageData
            ? _c(
                "div",
                {
                  staticClass:
                    "w-1 h-1 test-report-dialog-body-content-box fl-b-b",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1 flex-1 fl-c relative skin-test-results-images-change-slider-dialog-part-wrap-box",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-1 flex-1 fl-c skin-test-results-images-dialog-data-btn-show-content-part-box",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-1 flex-1 fl-c skin-test-results-images-data-btn-show-content-part-box relative",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1 h-1 fr-c skin-test-results-images-content-part-wrap-box text-no-select warp",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      ref: "areaBoxRef",
                                      staticClass:
                                        "w-1 fl-c skin-test-results-images-movable-area-part-box relative",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-contrast-date-time-part-box fr-c",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.formatTime(
                                                  _vm.contrast_entry_record_info
                                                    .startEntryRecordTime,
                                                  "yyyy-mm-dd hh:M"
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm.startSkinImgCurrentObj.skinBaseUrl
                                        ? _c(
                                            "div",
                                            {
                                              ref: "dragBoxRef",
                                              staticClass:
                                                "w-1 fl-c skin-test-results-images-movable-view-part-box",
                                              style: {
                                                transform: `scale(${_vm.scaleOffsetVal.scaleValue}) translate(${_vm.scaleOffsetVal.offsetValueX}px, ${_vm.scaleOffsetVal.offsetValueY}px)`,
                                              },
                                              on: {
                                                wheel: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleMouseWheelScaleScroll.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                mousedown:
                                                  _vm.handleMouseDownMove,
                                                touchmove: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleTouchMoveCallback.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                touchstart: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleTouchStartCallback.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                touchend: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleTouchEndCallback.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  ref: "imgRef",
                                                  staticClass:
                                                    "relative skin-test-results-images-box",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "iamges-style images-bottom-layer",
                                                    attrs: {
                                                      alt: "底层图片",
                                                      src: _vm
                                                        .startSkinImgCurrentObj[
                                                        _vm.skinTestBigType ==
                                                        "衰老"
                                                          ? "skinOriginUrl"
                                                          : "skinBaseUrl"
                                                      ],
                                                    },
                                                  }),
                                                  _vm.skinTestBigType == "衰老"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-1 relative overlay-layer-style-part-box",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-1 h-1 overlay-layer-box stack-iamges-box",
                                                              style: {
                                                                opacity:
                                                                  _vm.imgSlideVal /
                                                                  100,
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "iamges-style iamges-stack",
                                                                attrs: {
                                                                  alt: "叠加图片",
                                                                  src: _vm
                                                                    .startSkinImgCurrentObj
                                                                    .skinBaseUrl,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      ref: "areaBoxRef",
                                      staticClass:
                                        "w-1 fl-c skin-test-results-images-movable-area-part-box m-l-10 relative",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-contrast-date-time-part-box fr-c",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$util.formatTime(
                                                  _vm.contrast_entry_record_info
                                                    .endEntryRecordTime,
                                                  "yyyy-mm-dd hh:M"
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm.endSkinImgCurrentObj.skinBaseUrl
                                        ? _c(
                                            "div",
                                            {
                                              ref: "dragBoxRef",
                                              staticClass:
                                                "w-1 fl-c skin-test-results-images-movable-view-part-box",
                                              style: {
                                                transform: `scale(${_vm.scaleOffsetVal.scaleValue}) translate(${_vm.scaleOffsetVal.offsetValueX}px, ${_vm.scaleOffsetVal.offsetValueY}px)`,
                                              },
                                              on: {
                                                wheel: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleMouseWheelScaleScroll.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                mousedown:
                                                  _vm.handleMouseDownMove,
                                                touchmove: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleTouchMoveCallback.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                touchstart: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleTouchStartCallback.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                touchend: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleTouchEndCallback.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  ref: "imgRef",
                                                  staticClass:
                                                    "relative skin-test-results-images-box",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "iamges-style images-bottom-layer",
                                                    attrs: {
                                                      alt: "底层图片",
                                                      src: _vm
                                                        .endSkinImgCurrentObj[
                                                        _vm.skinTestBigType ==
                                                        "衰老"
                                                          ? "skinOriginUrl"
                                                          : "skinBaseUrl"
                                                      ],
                                                    },
                                                  }),
                                                  _vm.skinTestBigType == "衰老"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-1 relative overlay-layer-style-part-box",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-1 h-1 overlay-layer-box stack-iamges-box",
                                                              style: {
                                                                opacity:
                                                                  _vm.imgSlideVal /
                                                                  100,
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "iamges-style iamges-stack",
                                                                attrs: {
                                                                  alt: "叠加图片",
                                                                  src: _vm
                                                                    .endSkinImgCurrentObj
                                                                    .skinBaseUrl,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.isShowPageLeftData
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "left-top-fixed-contrast-data-show-part-wrap-box",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1 left-top-fixed-contrast-data-part-box",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table-left-top-fixed-contrast-data-box",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1 tr-contrast-data-box th-tr-data-box fr-b-c",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "th-data-item-box fr-s-c th-1",
                                                    },
                                                    [_vm._v("参数")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "th-data-item-box fr-e-c th-2 t-a-c",
                                                    },
                                                    [_vm._v("报告1")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "th-data-item-box fr-e-c th-3 t-a-c",
                                                    },
                                                    [_vm._v("报告2")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "th-data-item-box fr-e-c th-4",
                                                    },
                                                    [_vm._v("变化")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1 toby-contrast-data-box fl-s-c",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-1 scroll-toby-box",
                                                    },
                                                    [
                                                      _vm.skinTestBigType ==
                                                      "紫外线"
                                                        ? _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "w-1 contrast-data-content-box",
                                                            },
                                                            [
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-s-c td-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-line-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "得分"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            0
                                                                          )
                                                                            .acneScore
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            1
                                                                          )
                                                                            .acneScore
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-c td-4",
                                                                    },
                                                                    [
                                                                      _vm.fmtContrastNum(
                                                                        _vm.fmtZyxShowInfo(
                                                                          0
                                                                        )
                                                                          .acneScore,
                                                                        _vm.fmtZyxShowInfo(
                                                                          1
                                                                        )
                                                                          .acneScore
                                                                      ) == 1
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_rise,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm.fmtContrastNum(
                                                                            _vm.fmtZyxShowInfo(
                                                                              0
                                                                            )
                                                                              .acneScore,
                                                                            _vm.fmtZyxShowInfo(
                                                                              1
                                                                            )
                                                                              .acneScore
                                                                          ) == 2
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_drop,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "- -"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-s-c td-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-line-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "症状程度"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.fmtZyxShowInfo(
                                                                              0
                                                                            )
                                                                              .degreeLevel ==
                                                                              1
                                                                              ? "轻度"
                                                                              : _vm.fmtZyxShowInfo(
                                                                                  0
                                                                                )
                                                                                  .degreeLevel ==
                                                                                2
                                                                              ? "中度"
                                                                              : _vm.fmtZyxShowInfo(
                                                                                  0
                                                                                )
                                                                                  .degreeLevel ==
                                                                                3
                                                                              ? "重度"
                                                                              : "-"
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.fmtZyxShowInfo(
                                                                              1
                                                                            )
                                                                              .degreeLevel ==
                                                                              1
                                                                              ? "轻度"
                                                                              : _vm.fmtZyxShowInfo(
                                                                                  1
                                                                                )
                                                                                  .degreeLevel ==
                                                                                2
                                                                              ? "中度"
                                                                              : _vm.fmtZyxShowInfo(
                                                                                  1
                                                                                )
                                                                                  .degreeLevel ==
                                                                                3
                                                                              ? "重度"
                                                                              : "-"
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-c td-4",
                                                                    },
                                                                    [
                                                                      _vm.fmtContrastNum(
                                                                        _vm.fmtZyxShowInfo(
                                                                          0
                                                                        )
                                                                          .degreeLevel,
                                                                        _vm.fmtZyxShowInfo(
                                                                          1
                                                                        )
                                                                          .degreeLevel
                                                                      ) == 1
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_rise,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm.fmtContrastNum(
                                                                            _vm.fmtZyxShowInfo(
                                                                              0
                                                                            )
                                                                              .degreeLevel,
                                                                            _vm.fmtZyxShowInfo(
                                                                              1
                                                                            )
                                                                              .degreeLevel
                                                                          ) == 2
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_drop,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "- -"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-s-c td-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-line-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "数量"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            0
                                                                          )
                                                                            .acneNum
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            1
                                                                          )
                                                                            .acneNum
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-c td-4",
                                                                    },
                                                                    [
                                                                      _vm.fmtContrastNum(
                                                                        _vm.fmtZyxShowInfo(
                                                                          0
                                                                        )
                                                                          .acneNum,
                                                                        _vm.fmtZyxShowInfo(
                                                                          1
                                                                        )
                                                                          .acneNum
                                                                      ) == 1
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_rise,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm.fmtContrastNum(
                                                                            _vm.fmtZyxShowInfo(
                                                                              0
                                                                            )
                                                                              .acneNum,
                                                                            _vm.fmtZyxShowInfo(
                                                                              1
                                                                            )
                                                                              .acneNum
                                                                          ) == 2
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_drop,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "- -"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-s-c td-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-line-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "面积mm²"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            0
                                                                          )
                                                                            .acneArea
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            1
                                                                          )
                                                                            .acneArea
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-c td-4",
                                                                    },
                                                                    [
                                                                      _vm.fmtContrastNum(
                                                                        _vm.fmtZyxShowInfo(
                                                                          0
                                                                        )
                                                                          .acneArea,
                                                                        _vm.fmtZyxShowInfo(
                                                                          1
                                                                        )
                                                                          .acneArea
                                                                      ) == 1
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_rise,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm.fmtContrastNum(
                                                                            _vm.fmtZyxShowInfo(
                                                                              0
                                                                            )
                                                                              .acneArea,
                                                                            _vm.fmtZyxShowInfo(
                                                                              1
                                                                            )
                                                                              .acneArea
                                                                          ) == 2
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_drop,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "- -"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "w-1 tr-contrast-data-box td-tr-data-box fr-b-c",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-s-c td-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-line-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "面积占比"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            0
                                                                          )
                                                                            .acneRatio
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-e-c td-3",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.fmtZyxShowInfo(
                                                                            1
                                                                          )
                                                                            .acneRatio
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "td-data-item-box fr-c td-4",
                                                                    },
                                                                    [
                                                                      _vm.fmtContrastNum(
                                                                        _vm.fmtZyxShowInfo(
                                                                          0
                                                                        )
                                                                          .acneRatio,
                                                                        _vm.fmtZyxShowInfo(
                                                                          1
                                                                        )
                                                                          .acneRatio
                                                                      ) == 1
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_rise,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm.fmtContrastNum(
                                                                            _vm.fmtZyxShowInfo(
                                                                              0
                                                                            )
                                                                              .acneRatio,
                                                                            _vm.fmtZyxShowInfo(
                                                                              1
                                                                            )
                                                                              .acneRatio
                                                                          ) == 2
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "images-contrast",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.data_contrast_drop,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "- -"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "right-top-fixed-btn-show-part-wrap-box",
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "w-1 right-top-fixed-btn-show-content-part-box fl-s-c",
                                    },
                                    [
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "right-top-btn-item-box fl-c",
                                          class: {
                                            active: _vm.faceDirection == -1,
                                            disable:
                                              !_vm.fmtIsDirection(-1) ||
                                              _vm.skinTestBigType == "衰老",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickRightTopBtnItem(
                                                -1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn-item-iocn-box fr-c",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "images-btn-icon",
                                                attrs: {
                                                  alt: "左脸",
                                                  src:
                                                    _vm.faceDirection == -1
                                                      ? _vm.left_face_active
                                                      : _vm.left_face_default,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-12 btn-item-text",
                                            },
                                            [_vm._v("左脸")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "right-top-btn-item-box fl-c",
                                          class: {
                                            active: _vm.faceDirection == 0,
                                            disable: !_vm.fmtIsDirection(0),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickRightTopBtnItem(0)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn-item-iocn-box fr-c",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "images-btn-icon",
                                                attrs: {
                                                  alt: "正脸",
                                                  src:
                                                    _vm.faceDirection == 0
                                                      ? _vm.main_face_active
                                                      : _vm.main_face_default,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-12 btn-item-text",
                                            },
                                            [_vm._v("正脸")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "right-top-btn-item-box fl-c",
                                          class: {
                                            active: _vm.faceDirection == 1,
                                            disable:
                                              !_vm.fmtIsDirection(1) ||
                                              _vm.skinTestBigType == "衰老",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clickRightTopBtnItem(1)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn-item-iocn-box fr-c",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "images-btn-icon",
                                                attrs: {
                                                  alt: "右脸",
                                                  src:
                                                    _vm.faceDirection == 1
                                                      ? _vm.right_face_active
                                                      : _vm.right_face_default,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-12 btn-item-text",
                                            },
                                            [_vm._v("右脸")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-1 skin-test-results-type-change-slider-part-wrap-box fr-c-e",
                        },
                        [
                          _vm.isShowDragSlide
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1 h-1 skin-test-results-type-change-slider-content-part-box fr-c",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1 skin-test-results-slider-part-box fr-c",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "skin-test-results-slider-left-tag-box m-r-20",
                                        },
                                        [_vm._v(_vm._s(_vm.userAge) + "岁")]
                                      ),
                                      _c("el-slider", {
                                        attrs: {
                                          "show-tooltip": false,
                                          min: 1,
                                          max: 100,
                                        },
                                        model: {
                                          value: _vm.imgSlideVal,
                                          callback: function ($$v) {
                                            _vm.imgSlideVal = $$v
                                          },
                                          expression: "imgSlideVal",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "skin-test-results-slider-left-tag-box m-l-20",
                                        },
                                        [_vm._v("80岁")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1 dialog-body-bootom-skin-test-result-imgTab-part-wrap-box fr-c",
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "bootom-skin-test-result-imgTab-content-part-box fr-c",
                        },
                        _vm._l(_vm.startSkinImgTabList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "skin-test-imgTab-item-box fl-c",
                              class: {
                                active: _vm.skinTestBigType == item.skinText,
                                disable:
                                  _vm.faceDirection != 0 &&
                                  item.skinText == "衰老",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgTab-item-content-box fl-c",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clickSkinTestImgTabBtnItem(
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "imgTab-item-small-img-box fr-c relative",
                                    },
                                    [
                                      item.skinBaseUrl
                                        ? _c("img", {
                                            staticClass: "iamges-small-base",
                                            attrs: {
                                              alt: "底图",
                                              src: item.skinBaseUrl,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "text-12 imgTab-item-text" },
                                    [_vm._v(_vm._s(item.skinText))]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }