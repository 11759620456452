<template>
  <div class="test-report-dialog-wrap-part-box" :class="{ 'bg-ff-dialog': isShowImgWhiteBg }">
    <el-dialog custom-class="test-report-dialog-part-box" width="calc(100% - 20px)" :visible="is_dialog_visible"
      :close-on-click-modal="false" @close="closeTestReportDialog">
      <div class="w-1 h-1 test-report-dialog-body-content-box fl-b-b" v-if="isShowPageData">
        <div class="w-1 flex-1 fl-c relative skin-test-results-images-change-slider-dialog-part-wrap-box">
          <!-- 检测报告图片信息 -->
          <div class="w-1 flex-1 fl-c skin-test-results-images-dialog-data-btn-show-content-part-box">
            <div class="w-1 flex-1 fl-c skin-test-results-images-data-btn-show-content-part-box relative">
              <!-- 图片 -->
              <div class="w-1 h-1 fl-s-c skin-test-results-images-content-part-wrap-box text-no-select">
                <div class="w-1 fl-c skin-test-results-images-movable-area-part-box" ref="areaBoxRef"
                  v-if="skinImgCurrentObj.skinBaseUrl">
                  <div class="w-1 fl-c skin-test-results-images-movable-view-part-box" ref="dragBoxRef"
                    @wheel.prevent="handleMouseWheelScaleScroll" @mousedown="handleMouseDownMove"
                    @touchmove.prevent="handleTouchMoveCallback" @touchstart.prevent="handleTouchStartCallback"
                    @touchend.prevent="handleTouchEndCallback"
                    :style="{ transform: `scale(${scaleOffsetVal.scaleValue}) translate(${scaleOffsetVal.offsetValueX}px, ${scaleOffsetVal.offsetValueY}px)` }">
                    <div class="relative skin-test-results-images-box" ref="imgRef">
                      <!-- 底层图片 -->
                      <img class="iamges-style images-bottom-layer" alt="底层图片"
                        :src="skinImgCurrentObj[skinTestBigType == '衰老' ? 'skinOriginUrl' : 'skinBaseUrl']" />
                      <!-- 叠加层样式部分 -->
                      <div class="w-1 relative overlay-layer-style-part-box" v-if="skinTestBigType == '衰老'">
                        <!-- 叠加图片 -->
                        <div class="w-1 h-1 overlay-layer-box stack-iamges-box" :style="{ opacity: imgSlideVal / 100 }">
                          <img class="iamges-style iamges-stack" alt="叠加图片" :src="skinImgCurrentObj.skinBaseUrl" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 左侧数据部分 -->
              <div class="left-top-fixed-contrast-data-show-part-wrap-box" v-if="isShowPageLeftData">
                <!-- 原图 -->
                <ul class="left-top-data-content-box fr-b-s warp" v-if="skinTestBigType == '紫外线'">
                  <li class="left-top-data-item-box fr-s-c w-1">得分:{{ fmtZyxShowInfo.acneScore }}</li>
                  <li class="left-top-data-item-box fr-s-c">
                    症状程度:{{ fmtZyxShowInfo.degreeLevel == 1 ? '轻度' : fmtZyxShowInfo.degreeLevel == 2 ? '中度' :
                      fmtZyxShowInfo.degreeLevel == 3 ? '重度' : '-' }}
                  </li>
                  <li class="left-top-data-item-box fr-s-c">数量:{{ fmtZyxShowInfo.acneNum }}</li>
                  <li class="left-top-data-item-box fr-s-c">面积mm²:{{ fmtZyxShowInfo.acneArea }}</li>
                  <li class="left-top-data-item-box fr-s-c">面积占比:{{ fmtZyxShowInfo.acneRatio }}</li>
                </ul>
              </div>
              <!-- 右侧操作按钮部分 -->
              <div class="right-top-fixed-btn-show-part-wrap-box">
                <ul class="w-1 right-top-fixed-btn-show-content-part-box fl-s-c">
                  <!-- 左脸 -->
                  <li class="right-top-btn-item-box fl-c"
                    :class="{ 'active': faceDirection == -1, 'disable': !fmtIsDirection(-1) || skinTestBigType == '衰老' }"
                    @click.stop="clickRightTopBtnItem(1, -1)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="左脸"
                        :src="faceDirection == -1 ? left_face_active : left_face_default" />
                    </div>
                    <p class="text-12 btn-item-text">左脸</p>
                  </li>
                  <!-- 正脸 -->
                  <li class="right-top-btn-item-box fl-c"
                    :class="{ 'active': faceDirection == 0, 'disable': !fmtIsDirection(0) }"
                    @click.stop="clickRightTopBtnItem(1, 0)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="正脸"
                        :src="faceDirection == 0 ? main_face_active : main_face_default" />
                    </div>
                    <p class="text-12 btn-item-text">正脸</p>
                  </li>
                  <!-- 右脸 -->
                  <li class="right-top-btn-item-box fl-c"
                    :class="{ 'active': faceDirection == 1, 'disable': !fmtIsDirection(1) || skinTestBigType == '衰老' }"
                    @click.stop="clickRightTopBtnItem(1, 1)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="右脸"
                        :src="faceDirection == 1 ? right_face_active : right_face_default" />
                    </div>
                    <p class="text-12 btn-item-text">右脸</p>
                  </li>
                  <!-- 对比 -->
                  <li class="right-top-btn-item-box fl-c" @click.stop="clickRightTopBtnItem(2)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="对比" :src="contrast_default" />
                    </div>
                    <p class="text-12 btn-item-text">对比</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- 衰老展示滑动条部分 -->
          <div class="w-1 skin-test-results-type-change-slider-part-wrap-box fr-c-e">
            <div class="w-1 h-1 skin-test-results-type-change-slider-content-part-box fr-c" v-if="isShowDragSlide">
              <div class="w-1 skin-test-results-slider-part-box fr-c">
                <div class="skin-test-results-slider-left-tag-box m-r-20">{{ userAge }}岁</div>
                <el-slider :show-tooltip="false" :min="1" :max="100" v-model="imgSlideVal"></el-slider>
                <div class="skin-test-results-slider-left-tag-box m-l-20">80岁</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 页面底部-检测大类切换 -->
        <div class="w-1 dialog-body-bootom-skin-test-result-imgTab-part-wrap-box fr-c">
          <ul class="bootom-skin-test-result-imgTab-content-part-box fr-c ">
            <li class="skin-test-imgTab-item-box fl-c"
              :class="{ 'active': skinTestBigType == item.skinText, 'disable': faceDirection != 0 && item.skinText == '衰老' }"
              v-for="(item, index) in skinImgTabList" :key="index">
              <div class="imgTab-item-content-box fl-c" @click.stop="clickSkinTestImgTabBtnItem(item)">
                <div class="imgTab-item-small-img-box fr-c relative">
                  <img class="iamges-small-base" alt="底图" :src="item.skinBaseUrl" v-if="item.skinBaseUrl" />
                </div>
                <p class="text-12 imgTab-item-text">{{ item.skinText }}</p>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </el-dialog>

    <contrastTimeDialog v-if="isTimeDialogVisible" :is_time_dialog_visible.sync="isTimeDialogVisible" :seek_beauty_id="seek_beauty_id" :start_img_daylight="reportImgDaylight" :start_report_time="reportTime" 
      :contrast_time_data="contrastTimeList" @changeContrastTimeSuccess="changeContrastTimeSuccess">
    </contrastTimeDialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from '@/request/homeManager.js'
import { skinTestResults, skinTestImgTab } from './sub/skin_test_results';   //皮肤检测结果
import contrastTimeDialog from '@/components/common/subgroup/testReportDialog/ContrastChangeTimeDialog.vue'   //对比时间

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    contrastTimeDialog,  //对比时间
  },
  props: {
    is_dialog_visible: {   //是否显示
      type: Boolean,
      default: false
    },
    seek_beauty_id: {   //问诊记录ID
      type: [Number, String],
      default: 0
    },
    user_id: {   //问诊记录ID
      type: [Number, String],
      default: 0
    }
  },
  data() {
    // 这里存放数据
    return {
      left_face_default: require('@/assets/img/testReport/inspectReport_black_face_left_default_icon.png'), //左脸-默认-图标(黑底)
      left_face_active: require('@/assets/img/testReport/inspectReport_black_face_left_active_icon.png'), //左脸-选中-图标(黑底)
      main_face_default: require('@/assets/img/testReport/inspectReport_black_face_main_default_icon.png'), //正脸-默认-图标(黑底)
      main_face_active: require('@/assets/img/testReport/inspectReport_black_face_main_active_icon.png'), //正脸-选中-图标(黑底)
      right_face_default: require('@/assets/img/testReport/inspectReport_black_face_right_default_icon.png'), //右脸-默认-图标(黑底)
      right_face_active: require('@/assets/img/testReport/inspectReport_black_face_right_active_icon.png'), //右脸-选中-图标(黑底)
      contrast_default: require('@/assets/img/testReport/inspectReport_black_contrast_default_icon.png'), //对比-图标(黑底)
      slider_btn: require('@/assets/img/testReport/inspectReport_slider_btn_icon.png'), //滑块按钮-图标
      isShowImgWhiteBg: false,   //是否显示结果图片白色背景
      resultsListData: [], // skinTestResults().diagnosisSkinList,  //结果数据  [] 
      faceDirection: 0,  //面部方向(-1左,0中,1右)
      faceCurrentData: [], // skinTestResults().diagnosisSkinList.find(item => item.direction == 0),   //面部当前方向数据  [] 
      skinTestBigType: '冷光',   //皮肤检测大类 (冷光、自然光、红血丝、浅层红区、深层红区、红区热力、浅层棕区、深层棕区、紫外线、衰老、交叉偏振、平行偏振、紫外线斑、纹理)

      userAge: 0, //年龄
      faceDirectionStr: '', //脸部方向数据
      reportTime: 0, //检测报告时间
      reportImgDaylight: '',  //报告原图

      skinImgTabList: [],   //底部展示-全部类型切换
      skinImgCurrentObj: {},  //当前展示检测图片数据

      imgSlideVal: 0,   //图片-透明度
      isShowDragSlide: false,  //是否展示对比图-滑块

      scaleOffsetVal: {
        moveStart: { x: 0, y: 0 }, //拖拽元素初始位置
        scaleValue: 1,   //图片-缩放比例值
        offsetValueX: 0,  //移动X轴位置
        offsetValueY: 0,//移动Y轴位置
        distanceValue: 0,  //两指之间距离
        isZoom: false,   //是否缩放
      },

      isShowLoading: false,
      isShowPageData: true,   //是否显示页面数据  false

      isShowPageLeftData: false,   //是否显示固定左侧数据

      isTimeDialogVisible: false,  //选择对比时间
      contrastTimeList: []

    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPageSkinTestResultData();  //获取页面检测结果数据
    // this.skinImgTabList = skinTestImgTab(this.faceCurrentData)   //底部展示-全部类型切换
    // this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  // 方法集合
  methods: {
    // 获取页面检测结果数据
    async getPageSkinTestResultData() {
      this.isShowLoading = true
      try {
        let { code, data, message } = await homeManager.getReportDetail({ id: this.seek_beauty_id })  //入店记录id
        if (code == 200 && data) {
          var diagnosisSkinList = data.interfaceContent ? JSON.parse(data.interfaceContent) : []
          if (diagnosisSkinList.length) {
            this.faceDirectionStr = diagnosisSkinList.map(item => item.direction) //报告中脸部方向数据
            this.getCurrentDirectionValData();   //处理当前默认脸部方向值
            let face_current_obj = diagnosisSkinList.find(item => item.direction == this.faceDirection)  //面部当前方向数据
            this.resultsListData = diagnosisSkinList;
            this.faceCurrentData = face_current_obj  //面部当前方向数据

            this.reportTime = data.createTime //检测报告时间
            this.reportImgDaylight = face_current_obj.imgDaylight   //报告原图

            this.userAge = data.age  //年龄

            this.skinImgTabList = skinTestImgTab(face_current_obj)   //底部展示-全部类型切换 

            this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
          }
          this.isShowPageData = diagnosisSkinList.length

        } else {
          this.isShowPageData = false
        }
      } catch (error) {
        console.log('获取页面检测结果数据-error', error)
        this.isShowPageData = false
      } finally {
        this.isShowLoading = false
      }
    },


    // 选择时间成功
    changeContrastTimeSuccess(time_obj) {
      // this.closeTestReportDialog();  //关闭弹窗
      this.$emit('changeContrastTimeSuccess', time_obj)
    },


    // 点击切换检测报告-大类展示
    clickSkinTestImgTabBtnItem(item) {
      if (item.skinText == '衰老' && this.faceDirection != 0) return;
      this.skinTestBigType = item.skinText  //皮肤检测大类 (原图、表素、棕斑-白、深斑、红区-白、毛孔、粉刺、纹理)
      this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）

    },

    // 点击页面右上角固定-按钮
    clickRightTopBtnItem(btn_type, btn_value) {
      if (btn_type == 1) {
        if (!this.fmtIsDirection(btn_value) || (btn_value != 0 && this.skinTestBigType == '衰老')) return;
        let new_result_arr = this.resultsListData.find(item => item.direction == btn_value)

        this.faceDirection = btn_value   //面部方向(-1左,0中,1右)
        this.faceCurrentData = new_result_arr   //面部当前方向数据
        this.skinImgTabList = skinTestImgTab(new_result_arr);  //底部展示-全部类型切换
        this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
      } else if (btn_type == 2) {
        this.getPageContrastReportTimeData();  //获取检测报告对比时间信息
      }
    },
    // // 处理获取面部不同方向切换数据----暂时不用
    // getHandleFaceSkinImgTabData () {
    //   let { faceCurrentData, faceDirection } = this
    //   if (faceDirection == 0) {
    //     return skinTestImgTab(faceCurrentData);
    //   } else {
    //     return skinTestImgTab(faceCurrentData).filter(item => item.skinText != '衰老');
    //   }
    // },
    // 获取检测报告对比时间信息
    async getPageContrastReportTimeData() {
      try {
        let { code, data, message } = await homeManager.getCheckReportList({ userId: this.user_id })
        if (code == 200 && data) {
          this.contrastTimeList = data
          this.isTimeDialogVisible = true
        }
      } catch (error) {
        console.log('获取检测报告对比时间信息-error', error)
      }
    },




    // 滑动对比--X轴初始值（默认为展示X轴一半距离）
    getBallMoveInitXData() {
      let { skinImgTabList, skinTestBigType } = this   //从data中取数据
      this.isShowImgWhiteBg = this.getHandleCurrentImgZbhq()   //是否显示结果图片白色背景
      this.skinImgCurrentObj = skinImgTabList.find(item => skinTestBigType == item.skinText)  //当前展示检测图片数据
      this.isShowPageLeftData = this.getHandlePageLeftData()  //是否显示固定左侧数据
      this.isShowDragSlide = this.getHandleCurrentImgBswl()  //是否展示对比图-滑块
      this.imgSlideVal = 0   //图片-透明度
    },
    // 处理是否当前展示结果图片为 浅层红区、浅层棕区
    getHandleCurrentImgZbhq() {
      let { skinTestBigType } = this   //从data中取数据
      return skinTestBigType == '浅层红区' || skinTestBigType == '浅层棕区' || skinTestBigType == '深层红区' || skinTestBigType == '深层棕区' ? true : false;
    },
    // 处理是否当前展示结果图片为 衰老
    getHandleCurrentImgBswl() {
      let { skinTestBigType } = this   //从data中取数据
      return skinTestBigType == '衰老' ? true : false
    },
    // 处理是否显示左侧数据信息
    getHandlePageLeftData() {
      let { skinTestBigType } = this   //从data中取数据
      return skinTestBigType == '紫外线' ? true : false  //skinTestBigType == '深层红区' || skinTestBigType == '深层棕区' ||
    },


    // (移动端)手触摸-开始
    handleTouchStartCallback(e) {
      // console.log('(移动端)手触摸-开始', e)
      if (e.touches.length === 1) {  //单指移动
        let { clientX, clientY } = e.touches[0];
        this.scaleOffsetVal.moveStart.x = clientX
        this.scaleOffsetVal.moveStart.y = clientY
      } else {  //双指缩放
        let xMove = e.touches[1].clientX - e.touches[0].clientX;
        let yMove = e.touches[1].clientY - e.touches[0].clientY;
        let distance = Math.sqrt(xMove * xMove + yMove * yMove);
        this.scaleOffsetVal.distanceValue = distance
        this.scaleOffsetVal.isZoom = true
      }
    },
    // (移动端)手触摸-进行中
    handleTouchMoveCallback(e) {
      // console.log('(移动端)手触摸-进行中', e)
      let { isZoom, scaleValue, offsetValueX, offsetValueY, distanceValue, moveStart } = this.scaleOffsetVal
      if (e.touches.length === 1) {  //单指移动
        if (isZoom || scaleValue == 1) return; //缩放状态，不处理单指
        let { clientX, clientY } = e.touches[0];
        let offsetX = clientX - moveStart.x;
        let offsetY = clientY - moveStart.y;

        this.scaleOffsetVal.offsetValueX = offsetValueX + offsetX;
        this.scaleOffsetVal.offsetValueY = offsetValueY + offsetY;
        this.scaleOffsetVal.moveStart.x = clientX;
        this.scaleOffsetVal.moveStart.y = clientY;
        // console.log(`移动结果 x:${this.scaleOffsetVal.offsetValueX},y:${this.scaleOffsetVal.offsetValueY}`)

      } else {  //双指缩放
        let xMove = e.touches[1].clientX - e.touches[0].clientX;
        let yMove = e.touches[1].clientY - e.touches[0].clientY;
        let distance = Math.sqrt(xMove * xMove + yMove * yMove);
        let newScale = scaleValue + 0.005 * (distance - distanceValue);
        let scale_value = newScale > 1 ? newScale : 1;

        this.scaleOffsetVal.distanceValue = distance;
        this.scaleOffsetVal.scaleValue = scale_value;
        this.scaleOffsetVal.offsetValueX = scale_value > 1 ? offsetValueX : 0;
        this.scaleOffsetVal.offsetValueY = scale_value > 1 ? offsetValueY : 0;
        // console.log('scaleValue', this.scaleOffsetVal.scaleValue)
      }
    },
    // (移动端)手触摸-结束
    handleTouchEndCallback(e) {
      // console.log('(移动端)手触摸-结束', e)
      if (e.touches.length === 0) this.scaleOffsetVal.isZoom = false;
    },

    // (PC端)鼠标滚轮事件-缩放
    handleMouseWheelScaleScroll(e) {
      let { scaleValue, offsetValueX, offsetValueY } = this.scaleOffsetVal
      const delta_val = Math.sign(-e.deltaY) * 0.1;
      let scale_val = scaleValue + delta_val
      if (scale_val >= 1 && scale_val <= 4) this.scaleOffsetVal.scaleValue = scale_val;
      this.scaleOffsetVal.offsetValueX = scale_val > 1 ? offsetValueX : 0;
      this.scaleOffsetVal.offsetValueY = scale_val > 1 ? offsetValueY : 0;
      // console.log('(PC端)鼠标滚轮事件-缩放', this.scaleOffsetVal.scaleValue)
    },
    // (PC端)鼠标拖拽事件-移动
    handleMouseDownMove(e) {
      // console.log('(PC端)鼠标拖拽事件-移动',e)
      if (e.button !== 0) return;
      if (this.scaleOffsetVal.scaleValue == 1) return;
      this.scaleOffsetVal.moveStart.x = e.clientX
      this.scaleOffsetVal.moveStart.y = e.clientY
      // 鼠标拖动事件
      document.onmousemove = (evt) => {
        // console.log('(PC端)鼠标拖动事件', evt)
        let { scaleValue, moveStart } = this.scaleOffsetVal
        this.scaleOffsetVal.offsetValueX += (evt.clientX - moveStart.x) / scaleValue
        this.scaleOffsetVal.offsetValueY += (evt.clientY - moveStart.y) / scaleValue
        this.scaleOffsetVal.moveStart.x = evt.clientX
        this.scaleOffsetVal.moveStart.y = evt.clientY
        // console.log('结果-(PC端)鼠标拖拽事件-移动', this.scaleOffsetVal.offsetValueX, this.scaleOffsetVal.offsetValueY)
      };
      document.onmouseup = () => { document.onmousemove = null; };
    },


    // 关闭弹窗
    closeTestReportDialog() {
      this.$emit("update:is_dialog_visible", false);
    },


    // 处理当前默认脸部方向值
    getCurrentDirectionValData() {
      this.faceDirection = this.faceDirectionStr.length ? this.faceDirectionStr[0] : 0;
    },
    fmtIsDirection() {   //是否有当前面部方向
      return (direction_val) => {
        return this.faceDirectionStr.indexOf(direction_val) != -1;
      }
    },

  },
  // 监听属性 类似于data概念
  computed: {
    fmtZyxShowInfo() {   //处理-紫外线-展示数据
      let zyx_show_data = {}
      let { diagnosisSkinDetailList, acneScore, acneNum, acneArea, acneRatio } = this.faceCurrentData
      let diagnosis_skin_data = diagnosisSkinDetailList.find(item => item.symptom == '08') || null;
      if (diagnosis_skin_data) {
        zyx_show_data = {
          acneScore: acneScore,   //卟啉得分
          degreeLevel: diagnosis_skin_data.degreeLevel, //症状程度
          acneNum: diagnosis_skin_data.counts,  //卟啉数量
          acneArea: diagnosis_skin_data.area,  //卟啉面积
          acneRatio: diagnosis_skin_data.areaRatio,  //卟啉占比
        }
      } else {
        zyx_show_data = {
          acneScore: acneScore,   //卟啉得分
          degreeLevel: acneScore >= 0 && acneScore <= 33 ? 1 : acneScore > 33 && acneScore <= 66 ? 2 : acneScore > 66 && acneScore <= 99 ? 3 : 0, //症状程度
          acneNum: acneNum,  //卟啉数量
          acneArea: acneArea,  //卟啉面积
          acneRatio: acneRatio,  //卟啉占比
        }
      }
      return zyx_show_data;
    },
    // diagnosisSkinDetailList.filter(item => item.symptom == '02').sort((a, b) =>a.degreeLevel - b.degreeLevel)  
  },
  // 生命周期 - 创建之前
  beforeCreate() { },
  // 生命周期 - 挂载之前
  beforeMount() { },
  // 生命周期 - 更新之前
  beforeUpdate() { },
  // 生命周期 - 更新之后
  updated() { },
  // 生命周期 - 销毁之前
  beforeDestroy() { },
  // 生命周期 - 销毁完成
  destroyed() { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() { },
}
</script>

<style scoped lang="scss">
/* 默认背景色 */
.test-report-dialog-wrap-part-box {
  margin-top: -60px;
  --img-width: 500px;
  --img-height: 663px;

  ::v-deep .test-report-dialog-part-box {
    height: calc(100% - 20px);
    background: #080302;
    border-radius: 12px;
    overflow: hidden;
    margin: -30px auto 10px !important;

    .el-dialog__header {
      padding: 0px !important;

      .el-dialog__headerbtn {
        z-index: 2;

        .el-dialog__close {
          color: #fafafa;
        }

        &:hover,
        &:focus {
          .el-dialog__close {
            font-weight: bold;
          }
        }
      }
    }

    .el-dialog__body {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      overflow-y: auto;
      padding: 0px 0px;
      box-sizing: border-box;

      .skin-test-results-images-change-slider-dialog-part-wrap-box {
        background: #080302;

        /* 检测报告图片信息 */
        .skin-test-results-images-dialog-data-btn-show-content-part-box {
          padding: 0px 20px;
          box-sizing: border-box;

          .skin-test-results-images-data-btn-show-content-part-box {
            max-width: 1200px;
            padding: 0px 0px 20px;
            box-sizing: border-box;

            .skin-test-results-images-movable-area-part-box {
              width: var(--img-width);
              height: var(--img-height);
              overflow: hidden;
              position: relative;
              cursor: move;

              .skin-test-results-images-movable-view-part-box {
                width: var(--img-width);
                height: var(--img-height);
                position: absolute;

                .skin-test-results-images-box,
                .overlay-layer-style-part-box,
                .iamges-style {
                  width: var(--img-width);
                  height: var(--img-height);
                }

                /* 底层图片 */
                .images-bottom-layer {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                }
              }
            }

            /* 左侧数据部分 */
            .left-top-fixed-contrast-data-show-part-wrap-box {
              width: 260px;
              background: rgba(0, 0, 0, 0.6);
              border-radius: 12px;
              position: absolute;
              top: 20px;
              left: 0px;
              z-index: 991;
              padding: 4px 12px;
              box-sizing: border-box;
              font-size: 14px;
              color: #fafafa;

              .left-top-data-item-box {
                width: 48%;
                padding: 4px 0px;
                box-sizing: border-box;
              }
            }

            /* 右侧操作按钮部分 */
            .right-top-fixed-btn-show-part-wrap-box {
              width: 40px;
              position: absolute;
              top: 98px;
              right: 10px;

              .right-top-btn-item-box {
                width: 100%;
                cursor: pointer;

                &:not(:last-child) {
                  margin-bottom: 16px;
                }

                .btn-item-iocn-box {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-bottom: 6px;

                  .images-btn-icon {
                    width: 40px;
                    height: 40px;
                  }
                }

                .btn-item-text {
                  color: rgba(255, 255, 255, 0.7);
                }

                &:not(.disable):hover {
                  .btn-item-text {
                    color: rgba(255, 255, 255, 1);
                  }
                }

                &.active {
                  .btn-item-text {
                    color: rgba(108, 253, 255, 0.7);
                    font-weight: bold;
                  }

                  &:hover {
                    .btn-item-text {
                      color: rgba(108, 253, 255, 0.7);
                    }
                  }
                }

                &.disable {
                  cursor: not-allowed;
                  opacity: 0.6;
                }
              }
            }
          }
        }

        /* 衰老展示滑动条部分 */
        .skin-test-results-type-change-slider-part-wrap-box {
          height: 70px;
          background: linear-gradient(180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.06) 15%,
              rgba(0, 0, 0, 0.6) 100%);
          padding: 40px 20px 0px;
          box-sizing: border-box;
          position: absolute;
          left: 0px;
          bottom: 0px;
          z-index: 3;

          .skin-test-results-type-change-slider-content-part-box {
            max-width: 1200px;

            .skin-test-results-slider-part-box {
              height: 30px;

              .el-slider {
                width: 600px;

                .el-slider__runway {
                  height: 2px;
                  background: rgba(255, 255, 255, 0.4);
                  border-radius: 0px;
                  margin: 0px;

                  .el-slider__bar {
                    height: 2px;
                    background: #ffffff;
                    border-radius: 0px;
                  }

                  .el-slider__button-wrapper {
                    width: 32px;
                    height: 32px;
                    top: -11px;
                    transform: translateX(-50%);

                    .el-slider__button {
                      width: 32px;
                      height: 32px;
                      border: none;
                      background-color: transparent;
                      border-radius: 50%;
                      background-image: url('../../../../assets/img/testReport/inspectReport_slider_btn_icon.png');
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                    }

                    .el-slider__button:hover,
                    .el-slider__button.hover,
                    .el-slider__button.dragging {
                      transform: none;
                    }
                  }
                }
              }

              .skin-test-results-slider-left-tag-box {
                width: 51px;
                height: 30px;
                background: #ffffff;
                border-radius: 16px;
                font-size: 16px;
                font-weight: bold;
                color: #323232;
                line-height: 30px;
                text-align: center;
              }
            }
          }
        }
      }

      /* 页面底部-检测大类切换 */
      .dialog-body-bootom-skin-test-result-imgTab-part-wrap-box {
        min-width: 600px;
        height: 124px;
        padding: 26px 20px 20px;
        box-sizing: border-box;

        .bootom-skin-test-result-imgTab-content-part-box {
          .skin-test-imgTab-item-box {
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 20px;
            }

            .imgTab-item-small-img-box {
              width: 38px;
              height: 44px;
              border-radius: 8px;
              border: 1px solid rgba(255, 255, 255, 0.4);
              overflow: hidden;
              margin-bottom: 5px;

              .iamges-small-base {
                width: 38px;
                height: 44px;
              }

              // .iamges-small-dest {    //切换叠加层--废弃
              //   width: 38px;
              //   height: 44px;
              //   position: absolute;
              //   top: 0px;
              //   left: 0px;
              // }
            }

            .imgTab-item-text {
              color: rgba(255, 255, 255, 0.6);
            }

            &:not(.disable):hover {
              .imgTab-item-text {
                color: rgba(255, 255, 255, 1);
              }
            }

            &.active {
              .imgTab-item-small-img-box {
                border-color: #ffffff;
              }

              .imgTab-item-text {
                color: rgba(255, 255, 255, 1);
                font-weight: bold;
              }
            }

            &.disable {
              cursor: not-allowed;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}

/* 白色-背景色 */
.test-report-dialog-wrap-part-box.bg-ff-dialog {
  ::v-deep .test-report-dialog-part-box {
    .el-dialog__header {
      .el-dialog__close {
        color: #909090;
      }
    }

    .el-dialog__body {
      .skin-test-results-images-change-slider-dialog-part-wrap-box {
        background: #ffffff;

        /* 检测报告图片信息 */
        .skin-test-results-images-dialog-data-btn-show-content-part-box {
          .skin-test-results-images-data-btn-show-content-part-box {

            /* 左侧数据部分 */
            .left-top-fixed-contrast-data-show-part-wrap-box {
              background: #ffffff;
              color: #323232;
            }

            /* 右侧操作按钮部分 */
            .right-top-fixed-btn-show-part-wrap-box {
              .right-top-btn-item-box {
                .btn-item-iocn-box {
                  background: rgba(0, 0, 0, 0.6);
                }

                .btn-item-text {
                  color: rgba(50, 50, 50, 1);
                }

                &:not(:last-child):hover {
                  .btn-item-text {
                    color: #6cfdff;
                  }
                }

                &.active {
                  &:not(:last-child):hover {
                    .btn-item-text {
                      color: #50b1b2;
                    }
                  }

                  .btn-item-iocn-box {
                    background: transparent;
                  }

                  .btn-item-text {
                    color: #50b1b2;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }

        /* 表素、纹理展示滑动条、症状/预测切换部分 */
        .skin-test-results-type-change-slider-part-wrap-box {
          background: linear-gradient(180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.06) 15%,
              rgba(0, 0, 0, 0.6) 100%);
        }
      }
    }
  }
}

.w-1 {
  width: 100% !important;
}
</style>
