export const skinTestResults = () => {
  return {
    "diagnosisId": "xxx", //美测方检测ID
    "createTime": "2024-02-23 17:35:38.000", //拍摄时间
    "cusOpenId": "xxxx", //第三方ID
    "deviceNo": "MC2000-ZYX", //设备号
    "diagnosisSkinList": [
      {
        "acneArea": 3911.5, //卟啉面积
        "acneNum": 666, //卟啉数量
        "acneRatio": 0.00285, //卟啉占比
        "acneScore": 0.77295, //卟啉得分
        "agingIndex": null, //衰老指数
        "brownspotAgingIndex": 1, //棕斑衰老指数
        "brownspotArea": 444606.5, //棕斑面积
        "brownspotNum": 286, //棕斑数量
        "brownspotRatio": 0.32341, //棕斑面积占比
        "brownspotScore": 0.60433, //棕斑得分
        "brownspotWeight": null, //棕斑衰老权重
        "diagnosisSkinDetailList": [{
            "area": 11656,
            "areaRatio": 0.00847854, //面积占比
            "counts": 50, //数量
            "degreeLevel": 2, //程度等级 （1轻，2中，3重）（1小，2中，3大）（1皮纹、2浅度皱纹、3中度皱纹、4深度皱纹）
            "symptom": "02"   //症状信息:症状(01:表素,02:棕色斑,03:紫外色斑,04:红区(敏感),05:水油(无),06:纹理,07:毛孔,08:卟啉,09:抬头纹,10:眉间纹,11:眼间纹,12:眶周纹,13:鱼尾纹,14:法令纹,15:口角纹,16:肤色(无))
          },{
            "area": 430088.5,
            "areaRatio": 0.31284511,
            "counts": 231,
            "degreeLevel": 1,
            "symptom": "02"
          },{
            "area": 2862,
            "areaRatio": 0.00208181,
            "counts": 5,
            "degreeLevel": 3,
            "symptom": "02"
          },{
            "area": 129505,
            "areaRatio": 0.09420156,
            "counts": 124,
            "degreeLevel": 1,
            "symptom": "04"
          },{
            "area": 41449.5,
            "areaRatio": 0.03015024,
            "counts": 92,
            "degreeLevel": 2,
            "symptom": "04"
          },{
            "area": 9558,
            "areaRatio": 0.00695246,
            "counts": 18,
            "degreeLevel": 3,
            "symptom": "04"
          }, {
            "area": 7389.02587891,
            "areaRatio": 0.00537476,
            "counts": 48,
            "degreeLevel": 3,
            "symptom": "07"
          },{
            "area": 22619.46679688,
            "areaRatio": 0.01645333,
            "counts": 800,
            "degreeLevel": 1,
            "symptom": "07"
          },{
            "area": 90477.8671875,
            "areaRatio": 0.06581333,
            "counts": 1152,
            "degreeLevel": 2,
            "symptom": "07"
          },{
            "area": 5952,
            "areaRatio": 0.01853241,
            "counts": 16,
            "degreeLevel": 1,
            "symptom": "13"
          },{
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "13"
          },{
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "13"
          }, {
            "area": 7688,
            "areaRatio": 0.0239377,
            "counts": 6,
            "degreeLevel": 2,
            "symptom": "13"
          }
        ],
        "direction": -1, //面部方向(-1左,0中,1右)
        "imgAcnePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/acne.png",
        "imgAging": null,
        "imgBetweeneyePng": "",
        "imgBloodmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/bloodmap.jpg",
        "imgBrownHotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/brownHotmap.jpg",
        "imgBrownSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/brownSpot.png",
        "imgBrownmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/brownMap.jpg",
        "imgCormouthPng": "",
        "imgCross": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/cross.jpg",
        "imgDaylight": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/daylight.jpg",
        "imgDeepGraySpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/deepGraySpot.jpg",
        "imgDeepSpotPng": "",
        "imgForeheadPng": "",
        "imgGlabellarPng": "",
        "imgNasofoldsPng": "",
        "imgParallel": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/parallel.jpg",
        "imgPorePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/pore.png",
        "imgRedhotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/redhotmap.jpg",
        "imgRedmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/redmap.jpg",
        "imgSensitiveAreaPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/sensitiveArea.png",
        "imgSidePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/side.png",
        "imgSurfaceSpotAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/surfaceSpotAging.jpg",
        "imgSurfaceSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/surfaceSpot.png",
        "imgTexturePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/texture.png",
        "imgUndereyePng": "",
        "imgUv": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/uv.jpg",
        "imgUvHighContrast": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/uvHighContrast.jpg",
        "imgUvSpecial": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/left/uvSpecial.jpg",
        "isFinished": 0,
        "jsonAging": null,
        "poreArea": 120486.35938,
        "poreNum": 2000,
        "poreRatio": 0.08764,
        "poreScore": 0.06027,
        "redspotArea": 180512.5,
        "redspotNum": 234,
        "redspotRatio": 0.1313,
        "redspotScore": 0.42257,
        "skinAge": 19,
        "skinColor": "DARK",
        "skinScore": 0.40517,
        "surfacespotArea": 112050.5,
        "surfacespotNum": 181,
        "surfacespotRatio": 0.08151,
        "surfacespotScore": 0.35412,
        "textureArea": 192463,
        "textureNum": 2293,
        "textureRatio": 0.14,
        "textureScore": 0,
        "uvdeepspotArea": 105999,
        "uvdeepspotNum": 171,
        "uvdeepspotRatio": 0.0771,
        "uvdeepspotScore": 0.03125,
        "uvspotArea": null,
        "uvspotNum": null,
        "uvspotRatio": null,
        "uvspotScore": null,
        "wrinkleBetweeneyeAgingIndex": null,
        "wrinkleBetweeneyeScore": null,
        "wrinkleBetweeneyeWeight": null,
        "wrinkleCormouthAgingIndex": null,
        "wrinkleCormouthScore": null,
        "wrinkleCormouthWeight": null,
        "wrinkleForeheadAgingIndex": null,
        "wrinkleForeheadScore": null,
        "wrinkleForeheadWeight": null,
        "wrinkleGlabellarAgingIndex": null,
        "wrinkleGlabellarScore": null,
        "wrinkleGlabellarWeight": null,
        "wrinkleNasofoldsAgingIndex": null,
        "wrinkleNasofoldsScore": null,
        "wrinkleNasofoldsWeight": null,
        "wrinkleScore": 0.696,
        "wrinkleSideAgingIndex": 2,
        "wrinkleSideScore": 0.696,
        "wrinkleSideWeight": null,
        "wrinkleUndereyeAgingIndex": null,
        "wrinkleUndereyeScore": null,
        "wrinkleUndereyeWeight": null
      },
      {
        "acneArea": 6831,
        "acneNum": 851,
        "acneRatio": 0.00276,
        "acneScore": 0.7757,
        "agingIndex": 1,
        "brownspotAgingIndex": 1,
        "brownspotArea": 720920.5,
        "brownspotNum": 589,
        "brownspotRatio": 0.29148,
        "brownspotScore": 0.76402,
        "brownspotWeight": 0.12731,
        "diagnosisSkinDetailList": [
          {
            "area": 3148.5,
            "areaRatio": 0.00127301,
            "counts": 11,
            "degreeLevel": 3,
            "symptom": "02"
          },
          {
            "area": 682051.5,
            "areaRatio": 0.27576903,
            "counts": 386,
            "degreeLevel": 1,
            "symptom": "02"
          },
          {
            "area": 35720.5,
            "areaRatio": 0.01444261,
            "counts": 192,
            "degreeLevel": 2,
            "symptom": "02"
          },
          {
            "area": 74413,
            "areaRatio": 0.03008688,
            "counts": 175,
            "degreeLevel": 2,
            "symptom": "04"
          },
          {
            "area": 283170.5,
            "areaRatio": 0.1144923,
            "counts": 241,
            "degreeLevel": 1,
            "symptom": "04"
          },
          {
            "area": 9030,
            "areaRatio": 0.00365104,
            "counts": 21,
            "degreeLevel": 3,
            "symptom": "04"
          },
          {
            "area": 115846.2265625,
            "areaRatio": 0.04687358,
            "counts": 1475,
            "degreeLevel": 2,
            "symptom": "07"
          },
          {
            "area": 31441.05859375,
            "areaRatio": 0.01272165,
            "counts": 1112,
            "degreeLevel": 1,
            "symptom": "07"
          },
          {
            "area": 16933.18359375,
            "areaRatio": 0.00685149,
            "counts": 110,
            "degreeLevel": 3,
            "symptom": "07"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "09"
          },
          {
            "area": 1857,
            "areaRatio": 0.00196003,
            "counts": 10,
            "degreeLevel": 1,
            "symptom": "09"
          },
          {
            "area": 3877,
            "areaRatio": 0.00409211,
            "counts": 2,
            "degreeLevel": 3,
            "symptom": "09"
          },
          {
            "area": 9431.5,
            "areaRatio": 0.00995478,
            "counts": 11,
            "degreeLevel": 2,
            "symptom": "09"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "10"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "10"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 2,
            "symptom": "10"
          },
          {
            "area": 1237.5,
            "areaRatio": 0.00773525,
            "counts": 3,
            "degreeLevel": 1,
            "symptom": "10"
          },
          {
            "area": 379,
            "areaRatio": 0.00187993,
            "counts": 1,
            "degreeLevel": 2,
            "symptom": "11"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "11"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "11"
          },
          {
            "area": 7016,
            "areaRatio": 0.03480107,
            "counts": 21,
            "degreeLevel": 1,
            "symptom": "11"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "12"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "12"
          },
          {
            "area": 13751.5,
            "areaRatio": 0.02241297,
            "counts": 26,
            "degreeLevel": 1,
            "symptom": "12"
          },
          {
            "area": 2480.5,
            "areaRatio": 0.00404286,
            "counts": 9,
            "degreeLevel": 2,
            "symptom": "12"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 2,
            "symptom": "14"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 6,
            "symptom": "14"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "14"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 1,
            "symptom": "14"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 5,
            "symptom": "14"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "14"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "15"
          },
          {
            "area": 4237,
            "areaRatio": 0.07680734,
            "counts": 2,
            "degreeLevel": 1,
            "symptom": "15"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "15"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 5,
            "symptom": "15"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 2,
            "symptom": "15"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 6,
            "symptom": "15"
          }
        ],
        "direction": 0,
        "imgAcnePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/acne.png",
        "imgAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/aging.png",
        "imgBetweeneyePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/betweeneye.png",
        "imgBloodmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/bloodmap.jpg",
        "imgBrownHotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/brownHotmap.jpg",
        "imgBrownSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/brownSpot.png",
        "imgBrownmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/brownMap.jpg",
        "imgCormouthPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/cormouth.png",
        "imgCross": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/cross.jpg",
        "imgDaylight": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/daylight.jpg",
        "imgDeepGraySpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/deepGraySpot.jpg",
        "imgDeepSpotPng": "",
        "imgForeheadPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/forehead.png",
        "imgGlabellarPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/glabellar.png",
        "imgNasofoldsPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/nasofolds.png",
        "imgParallel": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/parallel.jpg",
        "imgPorePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/pore.png",
        "imgRedhotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/redhotmap.jpg",
        "imgRedmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/redmap.jpg",
        "imgSensitiveAreaPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/sensitiveArea.png",
        "imgSidePng": "",
        "imgSurfaceSpotAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/surfaceSpotAging.jpg",
        "imgSurfaceSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/surfaceSpot.png",
        "imgTexturePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/texture.png",
        "imgUndereyePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/undereye.png",
        "imgUv": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/uv.jpg",
        "imgUvHighContrast": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/uvHighContrast.jpg",
        "imgUvSpecial": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/uvSpecial.jpg",
        "isFinished": 1,
        "jsonAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/center/aging.json",
        "poreArea": 164220.46875,
        "poreNum": 2697,
        "poreRatio": 0.06645,
        "poreScore": 0.38192,
        "redspotArea": 366613.5,
        "redspotNum": 437,
        "redspotRatio": 0.14823,
        "redspotScore": 0.53416,
        "skinAge": 18,
        "skinColor": "DARK",
        "skinScore": 0.49508,
        "surfacespotArea": 263243,
        "surfacespotNum": 424,
        "surfacespotRatio": 0.10644,
        "surfacespotScore": 0.18865,
        "textureArea": 270513,
        "textureNum": 3249,
        "textureRatio": 0.10937,
        "textureScore": 0,
        "uvdeepspotArea": 201358,
        "uvdeepspotNum": 300,
        "uvdeepspotRatio": 0.08141,
        "uvdeepspotScore": 0.01019,
        "uvspotArea": null,
        "uvspotNum": null,
        "uvspotRatio": null,
        "uvspotScore": null,
        "wrinkleBetweeneyeAgingIndex": 1,
        "wrinkleBetweeneyeScore": 0.82807,
        "wrinkleBetweeneyeWeight": 0.14815,
        "wrinkleCormouthAgingIndex": 1,
        "wrinkleCormouthScore": 0.66333,
        "wrinkleCormouthWeight": 0,
        "wrinkleForeheadAgingIndex": 3,
        "wrinkleForeheadScore": 0.49175,
        "wrinkleForeheadWeight": 0.24191,
        "wrinkleGlabellarAgingIndex": 1,
        "wrinkleGlabellarScore": 0.95866,
        "wrinkleGlabellarWeight": 0.152,
        "wrinkleNasofoldsAgingIndex": 0,
        "wrinkleNasofoldsScore": 0.99,
        "wrinkleNasofoldsWeight": 0.06375,
        "wrinkleScore": 0.8024,
        "wrinkleSideAgingIndex": null,
        "wrinkleSideScore": null,
        "wrinkleSideWeight": 0,
        "wrinkleUndereyeAgingIndex": 1,
        "wrinkleUndereyeScore": 0.88259,
        "wrinkleUndereyeWeight": 0.1349
      },
      {
        "acneArea": 4109,
        "acneNum": 794,
        "acneRatio": 0.00258,
        "acneScore": 0.79303,
        "agingIndex": null,
        "brownspotAgingIndex": 1,
        "brownspotArea": 390115,
        "brownspotNum": 312,
        "brownspotRatio": 0.24542,
        "brownspotScore": 0.77803,
        "brownspotWeight": null,
        "diagnosisSkinDetailList": [
          {
            "area": 384137.5,
            "areaRatio": 0.24166264,
            "counts": 262,
            "degreeLevel": 1,
            "symptom": "02"
          },
          {
            "area": 4954,
            "areaRatio": 0.00311658,
            "counts": 43,
            "degreeLevel": 2,
            "symptom": "02"
          },
          {
            "area": 1023.5,
            "areaRatio": 0.00064389,
            "counts": 7,
            "degreeLevel": 3,
            "symptom": "02"
          },
          {
            "area": 135005,
            "areaRatio": 0.08493225,
            "counts": 153,
            "degreeLevel": 1,
            "symptom": "04"
          },
          {
            "area": 4343.5,
            "areaRatio": 0.00273252,
            "counts": 14,
            "degreeLevel": 3,
            "symptom": "04"
          },
          {
            "area": 44905,
            "areaRatio": 0.02824994,
            "counts": 121,
            "degreeLevel": 2,
            "symptom": "04"
          },
          {
            "area": 12007.16699219,
            "areaRatio": 0.00755376,
            "counts": 78,
            "degreeLevel": 3,
            "symptom": "07"
          },
          {
            "area": 27341.28125,
            "areaRatio": 0.01720052,
            "counts": 967,
            "degreeLevel": 1,
            "symptom": "07"
          },
          {
            "area": 100609.5,
            "areaRatio": 0.06329389,
            "counts": 1281,
            "degreeLevel": 2,
            "symptom": "07"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 3,
            "symptom": "13"
          },
          {
            "area": 9038.5,
            "areaRatio": 0.02365703,
            "counts": 28,
            "degreeLevel": 1,
            "symptom": "13"
          },
          {
            "area": 0,
            "areaRatio": 0,
            "counts": 0,
            "degreeLevel": 4,
            "symptom": "13"
          },
          {
            "area": 4204,
            "areaRatio": 0.01100339,
            "counts": 4,
            "degreeLevel": 2,
            "symptom": "13"
          }
        ],
        "direction": 1,
        "imgAcnePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/acne.png",
        "imgAging": null,
        "imgBetweeneyePng": "",
        "imgBloodmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/bloodmap.jpg",
        "imgBrownHotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/brownHotmap.jpg",
        "imgBrownSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/brownSpot.png",
        "imgBrownmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/brownMap.jpg",
        "imgCormouthPng": "",
        "imgCross": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/cross.jpg",
        "imgDaylight": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/daylight.jpg",
        "imgDeepGraySpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/deepGraySpot.jpg",
        "imgDeepSpotPng": "",
        "imgForeheadPng": "",
        "imgGlabellarPng": "",
        "imgNasofoldsPng": "",
        "imgParallel": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/parallel.jpg",
        "imgPorePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/pore.png",
        "imgRedhotmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/redhotmap.jpg",
        "imgRedmap": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/redmap.jpg",
        "imgSensitiveAreaPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/sensitiveArea.png",
        "imgSidePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/side.png",
        "imgSurfaceSpotAging": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/surfaceSpotAging.jpg",
        "imgSurfaceSpotPng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/surfaceSpot.png",
        "imgTexturePng": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/texture.png",
        "imgUndereyePng": "",
        "imgUv": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/uv.jpg",
        "imgUvHighContrast": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/uvHighContrast.jpg",
        "imgUvSpecial": "https://meice-meicepro.oss-cn-shanghai.aliyuncs.com/android/pro/4000/2982800173224165376/reports/test/right/uvSpecial.jpg",
        "isFinished": 0,
        "jsonAging": null,
        "poreArea": 139957.95313,
        "poreNum": 2326,
        "poreRatio": 0.08805,
        "poreScore": 0.07721,
        "redspotArea": 184253.5,
        "redspotNum": 288,
        "redspotRatio": 0.11591,
        "redspotScore": 0.59305,
        "skinAge": 19,
        "skinColor": "DARK",
        "skinScore": 0.45287,
        "surfacespotArea": 150865,
        "surfacespotNum": 256,
        "surfacespotRatio": 0.09491,
        "surfacespotScore": 0.31041,
        "textureArea": 241184,
        "textureNum": 2821,
        "textureRatio": 0.15173,
        "textureScore": 0,
        "uvdeepspotArea": 123465,
        "uvdeepspotNum": 180,
        "uvdeepspotRatio": 0.07767,
        "uvdeepspotScore": 0.03472,
        "uvspotArea": null,
        "uvspotNum": null,
        "uvspotRatio": null,
        "uvspotScore": null,
        "wrinkleBetweeneyeAgingIndex": null,
        "wrinkleBetweeneyeScore": null,
        "wrinkleBetweeneyeWeight": null,
        "wrinkleCormouthAgingIndex": null,
        "wrinkleCormouthScore": null,
        "wrinkleCormouthWeight": null,
        "wrinkleForeheadAgingIndex": null,
        "wrinkleForeheadScore": null,
        "wrinkleForeheadWeight": null,
        "wrinkleGlabellarAgingIndex": null,
        "wrinkleGlabellarScore": null,
        "wrinkleGlabellarWeight": null,
        "wrinkleNasofoldsAgingIndex": null,
        "wrinkleNasofoldsScore": null,
        "wrinkleNasofoldsWeight": null,
        "wrinkleScore": 0.70973,
        "wrinkleSideAgingIndex": 1,
        "wrinkleSideScore": 0.70973,
        "wrinkleSideWeight": null,
        "wrinkleUndereyeAgingIndex": null,
        "wrinkleUndereyeScore": null,
        "wrinkleUndereyeWeight": null
      }
    ],
    "directions": "111"
  }
}


export const skinTestImgTab = (face_data_obj) => {
  return [{
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgCoolMap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '冷光',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgDaylight,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '自然光',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgBloodmap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '红血丝',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgRedmap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '浅层红区',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgDeepRedMap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '深层红区',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgRedhotmap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '红区热力',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgBrownmap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '浅层棕区',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgDeepBrownMap,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '深层棕区',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgUv,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '紫外线',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgAging,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '衰老',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgCross,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '交叉偏振',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgParallel,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '平行偏振',
  }, {
    skinOriginUrl: face_data_obj.imgDaylight,   //原图
    skinBaseUrl: face_data_obj.imgUvHighContrast,   //底图
    skinDestUrl: '',   //叠加图
    skinText: '紫外线斑',
  }]
}