<template>
  <div class="test-report-dialog-wrap-part-box" :class="{ 'bg-ff-dialog': isShowImgWhiteBg }">
    <el-dialog custom-class="test-report-dialog-part-box" width="calc(100% - 20px)"
      :visible="is_contrast_dialog_visible" :close-on-click-modal="false" @close="closeTestReportDialog"
      :modal-append-to-body="false">
      <div class="w-1 h-1 test-report-dialog-body-content-box fl-b-b" v-if="isShowPageData">
        <div class="w-1 flex-1 fl-c relative skin-test-results-images-change-slider-dialog-part-wrap-box">
          <!-- 检测报告图片信息 -->
          <div class="w-1 flex-1 fl-c skin-test-results-images-dialog-data-btn-show-content-part-box">
            <div class="w-1 flex-1 fl-c skin-test-results-images-data-btn-show-content-part-box relative">
              <!-- 图片 -->
              <div class="w-1 h-1 fr-c skin-test-results-images-content-part-wrap-box text-no-select warp">
                <!-- 开始 -->
                <div class="w-1 fl-c skin-test-results-images-movable-area-part-box relative" ref="areaBoxRef">
                  <div class="report-contrast-date-time-part-box fr-c">
                    {{ $util.formatTime(contrast_entry_record_info.startEntryRecordTime, 'yyyy-mm-dd hh:M') }}</div>
                  <div class="w-1 fl-c skin-test-results-images-movable-view-part-box" ref="dragBoxRef"
                    @wheel.prevent="handleMouseWheelScaleScroll" @mousedown="handleMouseDownMove"
                    @touchmove.prevent="handleTouchMoveCallback" @touchstart.prevent="handleTouchStartCallback"
                    @touchend.prevent="handleTouchEndCallback"
                    :style="{ transform: `scale(${scaleOffsetVal.scaleValue}) translate(${scaleOffsetVal.offsetValueX}px, ${scaleOffsetVal.offsetValueY}px)` }"
                    v-if="startSkinImgCurrentObj.skinBaseUrl">
                    <div class="relative skin-test-results-images-box" ref="imgRef">
                      <!-- 底层图片 -->
                      <img class="iamges-style images-bottom-layer" alt="底层图片"
                        :src="startSkinImgCurrentObj[skinTestBigType == '衰老' ? 'skinOriginUrl' : 'skinBaseUrl']" />
                      <!-- 叠加层样式部分 -->
                      <div class="w-1 relative overlay-layer-style-part-box" v-if="skinTestBigType == '衰老'">
                        <!-- 叠加图片 -->
                        <div class="w-1 h-1 overlay-layer-box stack-iamges-box" :style="{ opacity: imgSlideVal / 100 }">
                          <img class="iamges-style iamges-stack" alt="叠加图片" :src="startSkinImgCurrentObj.skinBaseUrl" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 结束 -->
                <div class="w-1 fl-c skin-test-results-images-movable-area-part-box m-l-10 relative" ref="areaBoxRef">
                  <div class="report-contrast-date-time-part-box fr-c">
                    {{ $util.formatTime(contrast_entry_record_info.endEntryRecordTime, 'yyyy-mm-dd hh:M') }}</div>
                  <div class="w-1 fl-c skin-test-results-images-movable-view-part-box" ref="dragBoxRef"
                    @wheel.prevent="handleMouseWheelScaleScroll" @mousedown="handleMouseDownMove"
                    @touchmove.prevent="handleTouchMoveCallback" @touchstart.prevent="handleTouchStartCallback"
                    @touchend.prevent="handleTouchEndCallback"
                    :style="{ transform: `scale(${scaleOffsetVal.scaleValue}) translate(${scaleOffsetVal.offsetValueX}px, ${scaleOffsetVal.offsetValueY}px)` }"
                    v-if="endSkinImgCurrentObj.skinBaseUrl">
                    <div class="relative skin-test-results-images-box" ref="imgRef">
                      <!-- 底层图片 -->
                      <img class="iamges-style images-bottom-layer" alt="底层图片"
                        :src="endSkinImgCurrentObj[skinTestBigType == '衰老' ? 'skinOriginUrl' : 'skinBaseUrl']" />
                      <!-- 叠加层样式部分 -->
                      <div class="w-1 relative overlay-layer-style-part-box" v-if="skinTestBigType == '衰老'">
                        <!-- 叠加图片 -->
                        <div class="w-1 h-1 overlay-layer-box stack-iamges-box" :style="{ opacity: imgSlideVal / 100 }">
                          <img class="iamges-style iamges-stack" alt="叠加图片" :src="endSkinImgCurrentObj.skinBaseUrl" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 左侧数据部分 -->
              <div class="left-top-fixed-contrast-data-show-part-wrap-box" v-if="isShowPageLeftData">
                <div class="w-1 left-top-fixed-contrast-data-part-box">
                  <div class="table-left-top-fixed-contrast-data-box">
                    <div class="w-1 tr-contrast-data-box th-tr-data-box fr-b-c">
                      <div class="th-data-item-box fr-s-c th-1">参数</div>
                      <div class="th-data-item-box fr-e-c th-2 t-a-c">报告1</div>
                      <div class="th-data-item-box fr-e-c th-3 t-a-c">报告2</div>
                      <div class="th-data-item-box fr-e-c th-4">变化</div>
                    </div>
                    <div class="w-1 toby-contrast-data-box fl-s-c">
                      <div class="w-1 scroll-toby-box">
                        <ul class="w-1 contrast-data-content-box" v-if="skinTestBigType == '紫外线'">
                          <li class="w-1 tr-contrast-data-box td-tr-data-box fr-b-c">
                            <div class="td-data-item-box fr-s-c td-1"><span class="text-line-1">得分</span></div>
                            <div class="td-data-item-box fr-e-c td-2">{{ fmtZyxShowInfo(0).acneScore }}</div>
                            <div class="td-data-item-box fr-e-c td-3">{{ fmtZyxShowInfo(1).acneScore }}</div>
                            <div class="td-data-item-box fr-c td-4">
                              <img class="images-contrast" :src="data_contrast_rise"
                                v-if="fmtContrastNum(fmtZyxShowInfo(0).acneScore, fmtZyxShowInfo(1).acneScore) == 1" />
                              <img class="images-contrast" :src="data_contrast_drop"
                                v-else-if="fmtContrastNum(fmtZyxShowInfo(0).acneScore, fmtZyxShowInfo(1).acneScore) == 2" />
                              <span v-else>- -</span>
                            </div>
                          </li>
                          <li class="w-1 tr-contrast-data-box td-tr-data-box fr-b-c">
                            <div class="td-data-item-box fr-s-c td-1"><span class="text-line-1">症状程度</span></div>
                            <div class="td-data-item-box fr-e-c td-2">
                              {{ fmtZyxShowInfo(0).degreeLevel == 1 ? '轻度' : fmtZyxShowInfo(0).degreeLevel == 2 ? '中度' :
                                fmtZyxShowInfo(0).degreeLevel == 3 ? '重度' : '-' }}
                            </div>
                            <div class="td-data-item-box fr-e-c td-3">
                              {{ fmtZyxShowInfo(1).degreeLevel == 1 ? '轻度' : fmtZyxShowInfo(1).degreeLevel == 2 ? '中度' :
                                fmtZyxShowInfo(1).degreeLevel == 3 ? '重度' : '-' }}
                            </div>
                            <div class="td-data-item-box fr-c td-4">
                              <img class="images-contrast" :src="data_contrast_rise"
                                v-if="fmtContrastNum(fmtZyxShowInfo(0).degreeLevel, fmtZyxShowInfo(1).degreeLevel) == 1" />
                              <img class="images-contrast" :src="data_contrast_drop"
                                v-else-if="fmtContrastNum(fmtZyxShowInfo(0).degreeLevel, fmtZyxShowInfo(1).degreeLevel) == 2" />
                              <span v-else>- -</span>
                            </div>
                          </li>
                          <li class="w-1 tr-contrast-data-box td-tr-data-box fr-b-c">
                            <div class="td-data-item-box fr-s-c td-1"><span class="text-line-1">数量</span></div>
                            <div class="td-data-item-box fr-e-c td-2">{{ fmtZyxShowInfo(0).acneNum }}</div>
                            <div class="td-data-item-box fr-e-c td-3">{{ fmtZyxShowInfo(1).acneNum }}</div>
                            <div class="td-data-item-box fr-c td-4">
                              <img class="images-contrast" :src="data_contrast_rise"
                                v-if="fmtContrastNum(fmtZyxShowInfo(0).acneNum, fmtZyxShowInfo(1).acneNum) == 1" />
                              <img class="images-contrast" :src="data_contrast_drop"
                                v-else-if="fmtContrastNum(fmtZyxShowInfo(0).acneNum, fmtZyxShowInfo(1).acneNum) == 2" />
                              <span v-else>- -</span>
                            </div>
                          </li>
                          <li class="w-1 tr-contrast-data-box td-tr-data-box fr-b-c">
                            <div class="td-data-item-box fr-s-c td-1"><span class="text-line-1">面积mm²</span></div>
                            <div class="td-data-item-box fr-e-c td-2">{{ fmtZyxShowInfo(0).acneArea }}</div>
                            <div class="td-data-item-box fr-e-c td-3">{{ fmtZyxShowInfo(1).acneArea }}</div>
                            <div class="td-data-item-box fr-c td-4">
                              <img class="images-contrast" :src="data_contrast_rise"
                                v-if="fmtContrastNum(fmtZyxShowInfo(0).acneArea, fmtZyxShowInfo(1).acneArea) == 1" />
                              <img class="images-contrast" :src="data_contrast_drop"
                                v-else-if="fmtContrastNum(fmtZyxShowInfo(0).acneArea, fmtZyxShowInfo(1).acneArea) == 2" />
                              <span v-else>- -</span>
                            </div>
                          </li>
                          <li class="w-1 tr-contrast-data-box td-tr-data-box fr-b-c">
                            <div class="td-data-item-box fr-s-c td-1"><span class="text-line-1">面积占比</span></div>
                            <div class="td-data-item-box fr-e-c td-2">{{ fmtZyxShowInfo(0).acneRatio }}</div>
                            <div class="td-data-item-box fr-e-c td-3">{{ fmtZyxShowInfo(1).acneRatio }}</div>
                            <div class="td-data-item-box fr-c td-4">
                              <img class="images-contrast" :src="data_contrast_rise"
                                v-if="fmtContrastNum(fmtZyxShowInfo(0).acneRatio, fmtZyxShowInfo(1).acneRatio) == 1" />
                              <img class="images-contrast" :src="data_contrast_drop"
                                v-else-if="fmtContrastNum(fmtZyxShowInfo(0).acneRatio, fmtZyxShowInfo(1).acneRatio) == 2" />
                              <span v-else>- -</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 右侧操作按钮部分 -->
              <div class="right-top-fixed-btn-show-part-wrap-box">
                <ul class="w-1 right-top-fixed-btn-show-content-part-box fl-s-c">
                  <!-- 左脸 -->
                  <li class="right-top-btn-item-box fl-c"
                    :class="{ 'active': faceDirection == -1, 'disable': !fmtIsDirection(-1) || skinTestBigType == '衰老' }"
                    @click.stop="clickRightTopBtnItem(-1)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="左脸"
                        :src="faceDirection == -1 ? left_face_active : left_face_default" />
                    </div>
                    <p class="text-12 btn-item-text">左脸</p>
                  </li>
                  <!-- 正脸 -->
                  <li class="right-top-btn-item-box fl-c"
                    :class="{ 'active': faceDirection == 0, 'disable': !fmtIsDirection(0) }"
                    @click.stop="clickRightTopBtnItem(0)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="正脸"
                        :src="faceDirection == 0 ? main_face_active : main_face_default" />
                    </div>
                    <p class="text-12 btn-item-text">正脸</p>
                  </li>
                  <!-- 右脸 -->
                  <li class="right-top-btn-item-box fl-c"
                    :class="{ 'active': faceDirection == 1, 'disable': !fmtIsDirection(1) || skinTestBigType == '衰老' }"
                    @click.stop="clickRightTopBtnItem(1)">
                    <div class="btn-item-iocn-box fr-c">
                      <img class="images-btn-icon" alt="右脸"
                        :src="faceDirection == 1 ? right_face_active : right_face_default" />
                    </div>
                    <p class="text-12 btn-item-text">右脸</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- 衰老展示滑动条部分 -->
          <div class="w-1 skin-test-results-type-change-slider-part-wrap-box fr-c-e">
            <div class="w-1 h-1 skin-test-results-type-change-slider-content-part-box fr-c" v-if="isShowDragSlide">
              <div class="w-1 skin-test-results-slider-part-box fr-c">
                <div class="skin-test-results-slider-left-tag-box m-r-20">{{ userAge }}岁</div>
                <el-slider :show-tooltip="false" :min="1" :max="100" v-model="imgSlideVal"></el-slider>
                <div class="skin-test-results-slider-left-tag-box m-l-20">80岁</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 页面底部-检测大类切换 -->
        <div class="w-1 dialog-body-bootom-skin-test-result-imgTab-part-wrap-box fr-c">
          <ul class="bootom-skin-test-result-imgTab-content-part-box fr-c ">
            <li class="skin-test-imgTab-item-box fl-c"
              :class="{ 'active': skinTestBigType == item.skinText, 'disable': faceDirection != 0 && item.skinText == '衰老' }"
              v-for="(item, index) in startSkinImgTabList" :key="index">
              <div class="imgTab-item-content-box fl-c" @click.stop="clickSkinTestImgTabBtnItem(item)">
                <div class="imgTab-item-small-img-box fr-c relative">
                  <img class="iamges-small-base" alt="底图" :src="item.skinBaseUrl" v-if="item.skinBaseUrl" />
                </div>
                <p class="text-12 imgTab-item-text">{{ item.skinText }}</p>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import homeManager from '@/request/homeManager.js'
import { skinTestResults, skinTestImgTab } from './sub/skin_test_results';   //皮肤检测结果
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    is_contrast_dialog_visible: {   //是否显示
      type: Boolean,
      default: false
    },
    contrast_entry_record_info: {   //问诊记录ID（开始、结束信息）
      type: Object,
      default: () => {
        return {
          startEntryRecordId: 0,  //开始入店记录
          startEntryRecordTime: '',  //开始入店时间
          endEntryRecordId: 0,  //结束入店记录
          endEntryRecordTime: '',   //结束入店时间
        }
      }
    }
  },
  data() {
    // 这里存放数据
    return {
      left_face_default: require('@/assets/img/testReport/inspectReport_black_face_left_default_icon.png'), //左脸-默认-图标(黑底)
      left_face_active: require('@/assets/img/testReport/inspectReport_black_face_left_active_icon.png'), //左脸-选中-图标(黑底)
      main_face_default: require('@/assets/img/testReport/inspectReport_black_face_main_default_icon.png'), //正脸-默认-图标(黑底)
      main_face_active: require('@/assets/img/testReport/inspectReport_black_face_main_active_icon.png'), //正脸-选中-图标(黑底)
      right_face_default: require('@/assets/img/testReport/inspectReport_black_face_right_default_icon.png'), //右脸-默认-图标(黑底)
      right_face_active: require('@/assets/img/testReport/inspectReport_black_face_right_active_icon.png'), //右脸-选中-图标(黑底)
      data_contrast_rise: require('@/assets/img/testReport/inspectReport_contrast_pop_data_rise_icon.png'), //上升(对比报告)-图标
      data_contrast_drop: require('@/assets/img/testReport/inspectReport_contrast_pop_data_drop_icon.png'), //下降(对比报告)-图标
      slider_btn: require('@/assets/img/testReport/inspectReport_slider_btn_icon.png'), //滑块按钮-图标
      isShowImgWhiteBg: false,   //是否显示结果图片白色背景

      allResultsListData: [],   //全部-结果数据
      startResultsListData: [], // skinTestResults().diagnosisSkinList,  //开始-结果数据  
      endResultsListData: [], // skinTestResults().diagnosisSkinList,  //结束-结果数据  
      startFaceDirectionStr: '', //开始-报告中脸部方向数据
      endFaceDirectionStr: '', //结束-报告中脸部方向数据
      faceDirection: 0,  //面部方向(-1左,0中,1右)
      startFaceCurrentData: {},// skinTestResults().diagnosisSkinList.find(item => item.direction == 0),   //开始-面部当前方向数据 {} 
      endFaceCurrentData: {}, //skinTestResults().diagnosisSkinList.find(item => item.direction == 0),   //结束-面部当前方向数据  {} 
      skinTestBigType: '冷光',   //皮肤检测大类 (冷光、自然光、红血丝、浅层红区、深层红区、红区热力、浅层棕区、深层棕区、紫外线、衰老、交叉偏振、平行偏振、紫外线斑、纹理)

      userAge: 0,


      startSkinImgCurrentObj: {},  //开始-当前展示检测图片数据
      endSkinImgCurrentObj: {},  //结束-当前展示检测图片数据
      startSkinImgTabList: [],   //开始-底部展示-全部类型切换
      endSkinImgTabList: [],   //结束-底部展示-全部类型切换

      imgSlideVal: 0,   //图片-透明度
      isShowDragSlide: false,  //是否展示对比图-滑块

      scaleOffsetVal: {
        moveStart: { x: 0, y: 0 }, //拖拽元素初始位置
        scaleValue: 1,   //图片-缩放比例值
        offsetValueX: 0,  //移动X轴位置
        offsetValueY: 0,//移动Y轴位置
        distanceValue: 0,  //两指之间距离
        isZoom: false,   //是否缩放
      },

      isShowLoading: false,
      isShowPageData: true,   //是否显示页面数据  false

      isShowPageLeftData: false,   //是否显示固定左侧数据

    }
  },

  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPageSkinTestResultData();  //获取页面检测结果数据
    // this.startSkinImgTabList = skinTestImgTab(this.startFaceCurrentData)   //开始-底部展示-全部类型切换 
    // this.endSkinImgTabList = skinTestImgTab(this.endFaceCurrentData)   //结束-底部展示-全部类型切换 
    // this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  // 方法集合
  methods: {
    // 获取页面检测结果数据
    async getPageSkinTestResultData() {
      this.isShowLoading = true
      try {
        let { code, data, message } = await homeManager.reportContrast({
          seekBeautyIds: this.contrast_entry_record_info.startEntryRecordId + ',' + this.contrast_entry_record_info.endEntryRecordId,     //第一个入店记录id
        })
        if (code == 200 && data) {
          if (data.length == 2) {
            var fRecordDetail = data.find(item => item.id == this.contrast_entry_record_info.startEntryRecordId)
            
            var sRecordDetail = data.find(item => item.id == this.contrast_entry_record_info.endEntryRecordId)

            let start_content = JSON.parse(fRecordDetail.interfaceContent)
            let end_content = JSON.parse(sRecordDetail.interfaceContent)

            this.startFaceDirectionStr = start_content.map(item => item.direction) //开始-报告中脸部方向数据
            this.endFaceDirectionStr = end_content.map(item => item.direction) //结束-报告中脸部方向数据

            this.userAge = fRecordDetail.age
            this.getCurrentDirectionValData();   //处理当前默认脸部方向值

            let start_face_current_obj = start_content.find(item => item.direction == this.faceDirection)  //面部当前方向数据
            let end_face_current_obj = end_content.find(item => item.direction == this.faceDirection)  //面部当前方向数据
            this.allResultsListData = data   //全部-结果数据

            this.startResultsListData = start_content  //开始-结果数据
            this.endResultsListData = end_content   //结束-结果数据
            this.startFaceCurrentData = start_face_current_obj  //开始-当前展示检测图片数据
            this.endFaceCurrentData = end_face_current_obj  //结束-当前展示检测图片数据
            this.startSkinImgTabList = skinTestImgTab(start_face_current_obj)   //开始-底部展示-全部类型切换
            this.endSkinImgTabList = skinTestImgTab(end_face_current_obj)  //结束-底部展示-全部类型切换

            this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
            this.isShowPageData = true
          } else {
            this.isShowPageData = false
          }
        } else {
          this.isShowPageData = false
        }
      } catch (error) {
        console.log('获取页面检测结果数据-error', error)
        this.isShowPageData = false
      } finally {
        this.isShowLoading = false
      }
    },



    // 点击切换检测报告-大类展示
    clickSkinTestImgTabBtnItem(item) {
      if (item.skinText == '衰老' && this.faceDirection != 0) return;
      this.skinTestBigType = item.skinText  //皮肤检测大类 (原图、表素、棕斑-白、深斑、红区-白、毛孔、粉刺、纹理)
      this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）

    },

    // 点击页面右上角固定-按钮
    clickRightTopBtnItem(btn_value) {
      if (!this.fmtIsDirection(btn_value) || (btn_value != 0 && this.skinTestBigType == '衰老')) return;
      let start_new_result_arr = this.startResultsListData.find(item => item.direction == btn_value)
      let end_new_result_arr = this.endResultsListData.find(item => item.direction == btn_value)

      this.faceDirection = btn_value   //面部方向(-1左,0中,1右)
      this.startFaceCurrentData = start_new_result_arr   //开始-面部当前方向数据
      this.endFaceCurrentData = end_new_result_arr   //结束-面部当前方向数据
      this.startSkinImgTabList = skinTestImgTab(start_new_result_arr)  //开始-底部展示-全部类型切换
      this.endSkinImgTabList = skinTestImgTab(end_new_result_arr)  //结束-底部展示-全部类型切换
      this.getBallMoveInitXData();   //滑动对比--X轴初始值（默认为展示X轴一半距离）
    },


    // 滑动对比--X轴初始值（默认为展示X轴一半距离）
    getBallMoveInitXData() {
      let { startSkinImgTabList, endSkinImgTabList, skinTestBigType } = this   //从data中取数据
      this.isShowImgWhiteBg = this.getHandleCurrentImgZbhq()   //是否显示结果图片白色背景
      this.startSkinImgCurrentObj = startSkinImgTabList.find(item => skinTestBigType == item.skinText)  //开始-当前展示检测图片数据
      this.endSkinImgCurrentObj = endSkinImgTabList.find(item => skinTestBigType == item.skinText)  //开始-当前展示检测图片数据
      this.isShowPageLeftData = this.getHandlePageLeftData()  //是否显示固定左侧数据
      this.isShowDragSlide = this.getHandleCurrentImgBswl()  //是否展示对比图-滑块
      this.imgSlideVal = 0   //图片-透明度
    },
    // 处理是否当前展示结果图片为 浅层红区、浅层棕区
    getHandleCurrentImgZbhq() {
      let { skinTestBigType } = this   //从data中取数据
      return skinTestBigType == '浅层红区' || skinTestBigType == '浅层棕区' || skinTestBigType == '深层红区' || skinTestBigType == '深层棕区' ? true : false;
    },
    // 处理是否当前展示结果图片为 衰老
    getHandleCurrentImgBswl() {
      let { skinTestBigType } = this   //从data中取数据
      return skinTestBigType == '衰老' ? true : false
    },
    // 处理是否显示左侧数据信息
    getHandlePageLeftData() {
      let { skinTestBigType } = this   //从data中取数据
      return skinTestBigType == '紫外线' ? true : false  //skinTestBigType == '深层红区' || skinTestBigType == '深层棕区' || 
    },


    // (移动端)手触摸-开始
    handleTouchStartCallback(e) {
      // console.log('(移动端)手触摸-开始', e)
      if (e.touches.length === 1) {  //单指移动
        let { clientX, clientY } = e.touches[0];
        this.scaleOffsetVal.moveStart.x = clientX
        this.scaleOffsetVal.moveStart.y = clientY
      } else {  //双指缩放
        let xMove = e.touches[1].clientX - e.touches[0].clientX;
        let yMove = e.touches[1].clientY - e.touches[0].clientY;
        let distance = Math.sqrt(xMove * xMove + yMove * yMove);
        this.scaleOffsetVal.distanceValue = distance
        this.scaleOffsetVal.isZoom = true
      }
    },
    // (移动端)手触摸-进行中
    handleTouchMoveCallback(e) {
      // console.log('(移动端)手触摸-进行中', e)
      let { isZoom, scaleValue, offsetValueX, offsetValueY, distanceValue, moveStart } = this.scaleOffsetVal
      if (e.touches.length === 1) {  //单指移动
        if (isZoom || scaleValue == 1) return; //缩放状态，不处理单指
        let { clientX, clientY } = e.touches[0];
        let offsetX = clientX - moveStart.x;
        let offsetY = clientY - moveStart.y;

        this.scaleOffsetVal.offsetValueX = offsetValueX + offsetX;
        this.scaleOffsetVal.offsetValueY = offsetValueY + offsetY;
        this.scaleOffsetVal.moveStart.x = clientX;
        this.scaleOffsetVal.moveStart.y = clientY;
        // console.log(`移动结果 x:${this.scaleOffsetVal.offsetValueX},y:${this.scaleOffsetVal.offsetValueY}`)

      } else {  //双指缩放
        let xMove = e.touches[1].clientX - e.touches[0].clientX;
        let yMove = e.touches[1].clientY - e.touches[0].clientY;
        let distance = Math.sqrt(xMove * xMove + yMove * yMove);
        let newScale = scaleValue + 0.005 * (distance - distanceValue);
        let scale_value = newScale > 1 ? newScale : 1;

        this.scaleOffsetVal.distanceValue = distance;
        this.scaleOffsetVal.scaleValue = scale_value;
        this.scaleOffsetVal.offsetValueX = scale_value > 1 ? offsetValueX : 0;
        this.scaleOffsetVal.offsetValueY = scale_value > 1 ? offsetValueY : 0;
        // console.log('scaleValue', this.scaleOffsetVal.scaleValue)
      }
    },
    // (移动端)手触摸-结束
    handleTouchEndCallback(e) {
      // console.log('(移动端)手触摸-结束', e)
      if (e.touches.length === 0) this.scaleOffsetVal.isZoom = false;
    },

    // (PC端)鼠标滚轮事件-缩放
    handleMouseWheelScaleScroll(e) {
      let { scaleValue, offsetValueX, offsetValueY } = this.scaleOffsetVal
      const delta_val = Math.sign(-e.deltaY) * 0.1;
      let scale_val = scaleValue + delta_val
      if (scale_val >= 1 && scale_val <= 4) this.scaleOffsetVal.scaleValue = scale_val;
      this.scaleOffsetVal.offsetValueX = scale_val > 1 ? offsetValueX : 0;
      this.scaleOffsetVal.offsetValueY = scale_val > 1 ? offsetValueY : 0;
      // console.log('(PC端)鼠标滚轮事件-缩放', this.scaleOffsetVal.scaleValue)
    },
    // (PC端)鼠标拖拽事件-移动
    handleMouseDownMove(e) {
      // console.log('(PC端)鼠标拖拽事件-移动',e)
      if (e.button !== 0) return;
      if (this.scaleOffsetVal.scaleValue == 1) return;
      this.scaleOffsetVal.moveStart.x = e.clientX
      this.scaleOffsetVal.moveStart.y = e.clientY
      // 鼠标拖动事件
      document.onmousemove = (evt) => {
        // console.log('(PC端)鼠标拖动事件', evt)
        let { scaleValue, moveStart } = this.scaleOffsetVal
        this.scaleOffsetVal.offsetValueX += (evt.clientX - moveStart.x) / scaleValue
        this.scaleOffsetVal.offsetValueY += (evt.clientY - moveStart.y) / scaleValue
        this.scaleOffsetVal.moveStart.x = evt.clientX
        this.scaleOffsetVal.moveStart.y = evt.clientY
        // console.log('结果-(PC端)鼠标拖拽事件-移动', this.scaleOffsetVal.offsetValueX, this.scaleOffsetVal.offsetValueY)
      };
      document.onmouseup = () => { document.onmousemove = null; };
    },


    // 关闭弹窗
    closeTestReportDialog() {
      this.$emit("update:is_contrast_dialog_visible", false);
    },

    // 处理当前默认脸部方向值
    getCurrentDirectionValData() {
      this.faceDirection = this.startFaceDirectionStr.length ? this.startFaceDirectionStr[0] : 0;
    },
    fmtIsDirection() { //是否有当前面部方向
      return (direction_val) => {
        return (this.startFaceDirectionStr.indexOf(direction_val) != -1) && this.endFaceDirectionStr.indexOf(direction_val) != -1;
      }
    },

  },
  // 监听属性 类似于data概念
  computed: {
    fmtContrastNum(start_val, end_val) {   //对比数据箭头展示
      return (start_val, end_val) => {
        if (start_val < end_val) {
          return 1
        } else if (start_val > end_val) {
          return 2
        } else {
          return 0
        }
      }
    },
    fmtZyxShowInfo() {   //处理-紫外线-展示数据
      return (data_type = 0) => {
        let zyx_show_data = {}
        let { diagnosisSkinDetailList, acneScore, acneNum, acneArea, acneRatio } = this[data_type == 1 ? 'endFaceCurrentData' : 'startFaceCurrentData']
        let diagnosis_skin_data = diagnosisSkinDetailList.find(item => item.symptom == '08') || null;
        if (diagnosis_skin_data) {
          zyx_show_data = {
            acneScore: acneScore,   //卟啉得分
            degreeLevel: diagnosis_skin_data.degreeLevel, //症状程度
            acneNum: diagnosis_skin_data.counts,  //卟啉数量
            acneArea: diagnosis_skin_data.area,  //卟啉面积
            acneRatio: diagnosis_skin_data.areaRatio,  //卟啉占比
          }
        } else {
          zyx_show_data = {
            acneScore: acneScore,   //卟啉得分
            degreeLevel: acneScore >= 0 && acneScore <= 33 ? 1 : acneScore > 33 && acneScore <= 66 ? 2 : acneScore > 66 && acneScore <= 99 ? 3 : 0, //症状程度
            acneNum: acneNum,  //卟啉数量
            acneArea: acneArea,  //卟啉面积
            acneRatio: acneRatio,  //卟啉占比
          }
        }
        return zyx_show_data;
      }
    },



  },
  // 生命周期 - 创建之前
  beforeCreate() { },
  // 生命周期 - 挂载之前
  beforeMount() { },
  // 生命周期 - 更新之前
  beforeUpdate() { },
  // 生命周期 - 更新之后
  updated() { },
  // 生命周期 - 销毁之前
  beforeDestroy() { },
  // 生命周期 - 销毁完成
  destroyed() { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() { },
}
</script>

<style scoped lang="scss">
/* 默认背景色 */
.test-report-dialog-wrap-part-box {
  --img-width: 480px;
  --img-height: 596px;

  ::v-deep .el-dialog__wrapper {
    position: fixed !important;

    .test-report-dialog-part-box {
      min-height: calc(100% - 20px);
      height: calc(100% - 20px) !important;
      background: #080302;
      border-radius: 12px;
      overflow: hidden;
      margin: 10px auto 10px !important;

      .el-dialog__header {
        background: transparent;
        border: none;
        padding: 0px !important;

        .el-dialog__headerbtn {
          z-index: 2;

          .el-dialog__close {
            color: #fafafa;
          }

          &:hover,
          &:focus {
            .el-dialog__close {
              font-weight: bold;
            }
          }
        }
      }

      .el-dialog__body {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        overflow-y: auto !important;
        padding: 0px 0px !important;
        box-sizing: border-box;

        .skin-test-results-images-change-slider-dialog-part-wrap-box {
          background: #080302;

          /* 检测报告图片信息 */
          .skin-test-results-images-dialog-data-btn-show-content-part-box {
            padding: 0px 20px;
            box-sizing: border-box;

            .skin-test-results-images-data-btn-show-content-part-box {
              max-width: 1200px;
              padding: 0px 0px 40px;
              box-sizing: border-box;

              .skin-test-results-images-movable-area-part-box {
                width: var(--img-width);
                height: var(--img-height);
                overflow: hidden;
                position: relative;
                cursor: move;

                .report-contrast-date-time-part-box {
                  width: 141px;
                  height: 36px;
                  background: rgba(0, 0, 0, 0.8);
                  border-radius: 12px 12px 12px 12px;
                  border: 1px solid rgba(255, 255, 255, 0.4);
                  font-size: 14px;
                  color: #fafafa;
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 99;
                }

                .skin-test-results-images-movable-view-part-box {
                  width: var(--img-width);
                  height: var(--img-height);
                  position: absolute;

                  .skin-test-results-images-box,
                  .overlay-layer-style-part-box,
                  .iamges-style {
                    width: var(--img-width);
                    height: var(--img-height);
                  }

                  /* 底层图片 */
                  .images-bottom-layer {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }

                  /* 叠加层样式部分 */
                  .overlay-layer-box {
                    overflow: hidden;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }

                  .base-map-images-box {
                    z-index: 2;
                  }
                }
              }

              /* 左侧数据部分 */
              .left-top-fixed-contrast-data-show-part-wrap-box {
                width: 300px;
                background: rgba(0, 0, 0, 0.6);
                border-radius: 12px;
                position: absolute;
                top: 20px;
                left: -30px;
                z-index: 991;
                padding: 4px 12px;
                box-sizing: border-box;
                font-size: 14px;
                color: #fafafa;

                .left-top-fixed-contrast-data-part-box {
                  .table-left-top-fixed-contrast-data-box {
                    padding: 4px 0px 8px;
                    box-sizing: border-box;
                  }

                  .tr-contrast-data-box {
                    padding: 0px 5px 0px 6px;
                    box-sizing: border-box;

                    .th-data-item-box,
                    .td-data-item-box {
                      padding: 6px 4px;
                      box-sizing: border-box;
                      font-size: 14px;
                      color: #fafafa;
                      letter-spacing: -1px;
                    }

                    .th-1,
                    .td-1 {
                      width: 34%;
                    }

                    .th-2,
                    .td-2 {
                      width: 26%;
                    }

                    .th-3,
                    .td-3 {
                      width: 26%;
                    }

                    .th-4,
                    .td-4 {
                      width: 14%;
                    }

                    .images-contrast {
                      width: 18px;
                      height: 14px;
                      margin-left: 2px;
                    }
                  }

                  .scroll-toby-box {
                    min-height: 36px;
                    max-height: 215px;
                    overflow-y: auto;
                  }
                }
              }

              /* 右侧操作按钮部分 */
              .right-top-fixed-btn-show-part-wrap-box {
                width: 40px;
                position: absolute;
                top: 98px;
                right: 10px;

                .right-top-btn-item-box {
                  width: 100%;
                  cursor: pointer;

                  &:not(:last-child) {
                    margin-bottom: 16px;
                  }

                  .btn-item-iocn-box {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-bottom: 6px;

                    .images-btn-icon {
                      width: 40px;
                      height: 40px;
                    }
                  }

                  .btn-item-text {
                    color: rgba(255, 255, 255, 0.7);
                  }

                  &:not(.disable):hover {
                    .btn-item-text {
                      color: rgba(255, 255, 255, 1);
                    }
                  }

                  &.active {
                    .btn-item-text {
                      color: rgba(108, 253, 255, 0.7);
                      font-weight: bold;
                    }

                    &:hover {
                      .btn-item-text {
                        color: rgba(108, 253, 255, 0.7);
                      }
                    }
                  }

                  &.disable {
                    cursor: not-allowed;
                    opacity: 0.6;
                  }
                }
              }
            }
          }

          /* 衰老展示滑动条部分 */
          .skin-test-results-type-change-slider-part-wrap-box {
            height: 70px;
            background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.06) 15%,
                rgba(0, 0, 0, 0.6) 100%);
            padding: 40px 20px 0px;
            box-sizing: border-box;
            position: absolute;
            left: 0px;
            bottom: 0px;
            z-index: 3;

            .skin-test-results-type-change-slider-content-part-box {
              max-width: 1200px;

              .skin-test-results-slider-part-box {
                height: 30px;

                .el-slider {
                  width: 600px;

                  .el-slider__runway {
                    height: 2px;
                    background: rgba(255, 255, 255, 0.4);
                    border-radius: 0px;
                    margin: 0px;

                    .el-slider__bar {
                      height: 2px;
                      background: #ffffff;
                      border-radius: 0px;
                    }

                    .el-slider__button-wrapper {
                      width: 32px;
                      height: 32px;
                      top: -11px;
                      transform: translateX(-50%);

                      .el-slider__button {
                        width: 32px;
                        height: 32px;
                        border: none;
                        background-color: transparent;
                        border-radius: 50%;
                        background-image: url('../../../../assets/img/testReport/inspectReport_slider_btn_icon.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                      }

                      .el-slider__button:hover,
                      .el-slider__button.hover,
                      .el-slider__button.dragging {
                        transform: none;
                      }
                    }
                  }
                }

                .skin-test-results-slider-left-tag-box {
                  width: 51px;
                  height: 30px;
                  background: #ffffff;
                  border-radius: 16px;
                  font-size: 16px;
                  font-weight: bold;
                  color: #323232;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }
        }

        /* 页面底部-检测大类切换 */
        .dialog-body-bootom-skin-test-result-imgTab-part-wrap-box {
          min-width: 600px;
          height: 124px;
          padding: 26px 20px 20px;
          box-sizing: border-box;

          .bootom-skin-test-result-imgTab-content-part-box {
            .skin-test-imgTab-item-box {
              cursor: pointer;

              &:not(:last-child) {
                margin-right: 20px;
              }

              .imgTab-item-small-img-box {
                width: 38px;
                height: 44px;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.4);
                overflow: hidden;
                margin-bottom: 5px;

                .iamges-small-base {
                  width: 38px;
                  height: 44px;
                }

                // .iamges-small-dest {    //切换叠加层--废弃
                //   width: 38px;
                //   height: 44px;
                //   position: absolute;
                //   top: 0px;
                //   left: 0px;
                // }
              }

              .imgTab-item-text {
                color: rgba(255, 255, 255, 0.6);
              }

              &:not(.disable):hover {
                .imgTab-item-text {
                  color: rgba(255, 255, 255, 1);
                }
              }

              &.active {
                .imgTab-item-small-img-box {
                  border-color: #ffffff;
                }

                .imgTab-item-text {
                  color: rgba(255, 255, 255, 1);
                  font-weight: bold;
                }
              }

              &.disable {
                cursor: not-allowed;
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }
}

/* 白色-背景色 */
.test-report-dialog-wrap-part-box.bg-ff-dialog {
  ::v-deep .el-dialog__wrapper {
    .test-report-dialog-part-box {
      .el-dialog__header {
        .el-dialog__close {
          color: #909090;
        }
      }

      .el-dialog__body {
        .skin-test-results-images-change-slider-dialog-part-wrap-box {
          background: #ffffff;

          /* 检测报告图片信息 */
          .skin-test-results-images-dialog-data-btn-show-content-part-box {
            .skin-test-results-images-data-btn-show-content-part-box {

              /* 左侧数据部分 */
              // .left-top-fixed-contrast-data-show-part-wrap-box {
              //   background:rgba(255, 255, 255, 0.6);
              //   color: #323232;
              // }
              /* 右侧操作按钮部分 */
              .right-top-fixed-btn-show-part-wrap-box {
                .right-top-btn-item-box {
                  .btn-item-iocn-box {
                    background: rgba(0, 0, 0, 0.6);
                  }

                  .btn-item-text {
                    color: rgba(50, 50, 50, 1);
                  }

                  &:not(:last-child):hover {
                    .btn-item-text {
                      color: #6cfdff;
                    }
                  }

                  &.active {
                    &:not(:last-child):hover {
                      .btn-item-text {
                        color: #50b1b2;
                      }
                    }

                    .btn-item-iocn-box {
                      background: transparent;
                    }

                    .btn-item-text {
                      color: #50b1b2;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }

          /* 表素、纹理展示滑动条、症状/预测切换部分 */
          .skin-test-results-type-change-slider-part-wrap-box {
            background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.06) 15%,
                rgba(0, 0, 0, 0.6) 100%);
          }
        }
      }
    }
  }
}

.t-a-c {
  text-align: center;
}

.scroll-toby-box::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
</style>
