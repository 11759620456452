<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-select v-model="query.status" placeholder="请选择状态" clearable>
                        <el-option v-for="item in searchFrom.statusList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="permission.selectStoreList">
                    <el-select v-model="query.storeId" placeholder="请选择门店" clearable filterable>
                        <el-option v-for="item in searchFrom.storeList" :key="item.storeId" :label="item.storeName"
                            :value="item.storeId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker value-format="timestamp" v-model="query.startTime" type="datetime"
                        placeholder="请选择开始时间" :picker-options="searchPickerOptionsStart"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker value-format="timestamp" v-model="query.endTime" type="datetime" placeholder="结束时间"
                        :picker-options="searchPickerOptionsEnd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="nickName" label="用户名" align="center" min-width="100"></el-table-column>
                <el-table-column prop="storeName" label="门店名称" align="center" min-width="140"></el-table-column>
                <el-table-column prop="beauticianName" label="美容师名称" align="center" min-width="100"></el-table-column>
                <el-table-column prop="status" label="状态" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 5 && scope.row.relatedSolutionsId && scope.row.relatedSolutionsId>0">解决方案已关联</span>
                        <span v-else>{{ scope.row.status | getStateName(searchFrom.statusList) }}</span>
                        <!-- {{ scope.row.status | getStateName(searchFrom.statusList) }} -->
                    </template>
                </el-table-column>
                <el-table-column prop="beforeLeaveStatus" label="离店前状态" align="center" width="140">
                    <template slot-scope="scope">
                        <span v-if="scope.row.beforeLeaveStatus == 5 && scope.row.relatedSolutionsId && scope.row.relatedSolutionsId>0">解决方案已关联</span>
                        <span v-else>{{ scope.row.beforeLeaveStatus | getStateName(searchFrom.beforeLeaveStatusList) }}</span>
                        <!-- {{ scope.row.beforeLeaveStatus | getStateName(searchFrom.beforeLeaveStatusList) }} -->
                    </template>
                </el-table-column>
                <el-table-column prop="isCase" label="是否为案例" width="100" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isCase ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" width="100" align="center"
                    show-overflow-tooltip></el-table-column>
                <el-table-column prop="expireReason" label="失效原因" width="100" align="center"
                    show-overflow-tooltip></el-table-column>
                <el-table-column prop="entryTime" label="入店时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.entryTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="reportTime" label="报告生成时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.reportTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="symptomDescriptionTime" label="症状描述提交时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.symptomDescriptionTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="prescriptionFormTime" label="处方单开具时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.prescriptionFormTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="leaveTime" label="出店时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.leaveTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.isCase == 1 ? '取消案例' : '设置为案例'"
                            placement="top" v-if="permission.updateSeekBeautyLogNewInfo">
                            <el-popconfirm :title="scope.row.isCase == 1 ? '您确定要取消案例吗？' : '您确定要设置为案例吗？'"
                                icon-color="red" @confirm="updateSeekBeautyLogNewInfoHandle(scope.row)">
                                <el-button slot="reference"
                                    :icon="scope.row.isCase == 1 ? 'el-icon-open' : 'el-icon-turn-off'" type="text"
                                    :class="scope.row.isCase == 1 ? 'danger' : 'success'">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="日志详情" placement="top"
                            v-if="permission.getSeekBeautyLogDetail">
                            <el-button class="success" type="text" icon="el-icon-s-claim"
                                @click="solutionHandle(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="报告详情" placement="top"
                            v-if="permission.getReportDetail && scope.row.reportTime > 0">
                            <el-button class="success" type="text" icon="el-icon-tickets"
                                @click="getReportDetailHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>
        <!-- 查看解决方案 -->
        <el-dialog title="查看解决方案" :visible.sync="dialogLookSolutionVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <Solution :id="currentID" v-if="dialogLookSolutionVisible" />
        </el-dialog>
        <!-- 报告详情 -->
        <testReportDialog v-if="dialogLookReportDetailVisible" :is_dialog_visible.sync="dialogLookReportDetailVisible"
            :seek_beauty_id="currentID" :user_id="currentUserID" @changeContrastTimeSuccess="changeContrastTimeSuccess"></testReportDialog>

        <contrastTimeDialog v-if="isTimeDialogVisible" :is_time_dialog_visible.sync="isTimeDialogVisible"
            @changeContrastTimeSuccess="changeContrastTimeSuccess"></contrastTimeDialog>

        <contrastReportDialog v-if="isDialogContrastReportVisible"
            :is_contrast_dialog_visible.sync="isDialogContrastReportVisible"
            :contrast_entry_record_info="contrastEntryRecordInfo"></contrastReportDialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import beautyManager from '@/request/beautyManager.js'
import storeManager from '@/request/storeManager.js'
import Solution from '@/components/page/seekBeauty/entryRecord/sub/Solution.vue'
// import ReportDetail from '@/components/page/seekBeauty/entryRecord/sub/ReportDetail.vue'
import testReportDialog from '@/components/common/subgroup/testReportDialog/TestReportDialog.vue'   //检测报告
import contrastTimeDialog from '@/components/common/subgroup/testReportDialog/ContrastChangeTimeDialog.vue'   //对比时间
import contrastReportDialog from '@/components/common/subgroup/testReportDialog/ContrastReportDialog.vue'   //对比-检测报告

export default {
    mixins: [getList],
    props: ['userId', 'storeId', 'beauticianId'],
    // import引入的组件需要注入到对象中才能使用
    components: {
        Solution,
        testReportDialog,
        contrastTimeDialog,
        contrastReportDialog
        // ReportDetail
    },
    data() {
        // 这里存放数据
        return {
            searchPickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.query.endTime) {
                        return time.getTime() > this.query.endTime
                    }
                }
            },
            searchPickerOptionsEnd: {
                disabledDate: (time) => {
                    if (this.query.startTime) {
                        return time.getTime() < this.query.startTime
                    }
                },
            },
            requst: {
                module: beautyManager,
                url: 'seekBeautyLogNewList',
                addUrl: '',
                updateUrl: '',
            },
            query: {
                userId: this.userId ? this.userId : '',
                storeId: this.storeId ? this.storeId : '',
                beauticianId: this.beauticianId ? this.beauticianId : '',
                status: '',
                startTime: '',
                endTime: '',
            },

            searchFrom: {
                // 状态(1已入店，2检测报告已生成，3症状描述已完成，4面诊已完成，5处方单已开具，6已支付 ，7服务实施已完成，8已离店，9已失效)
                statusList: [{
                    value: 1,
                    label: '已入店'
                }, {
                    value: 2,
                    label: '检测报告已生成'
                }, {
                    value: 3,
                    label: '症状描述已完成'
                }, {
                    value: 4,
                    label: '面诊已完成'
                }, {
                    value: 5,
                    label: '处方单已开具'
                }, {
                    value: 6,
                    label: '已支付'
                }, {
                    value: 7,
                    label: '服务实施已完成'
                }, {
                    value: 8,
                    label: '已离店'
                }, {
                    value: 9,
                    label: '已失效'
                }],
                // 离店前状态(0未离店，1到店未检测离店，2检测报告生成后离店，3症状描述完成后离店，4面诊完成后离店，5处方单开具后未支付离店，6处方单支付后离店，7服务实施完成后离店)
                beforeLeaveStatusList: [{
                    value: 0,
                    label: '未离店'
                }, {
                    value: 1,
                    label: '到店未检测离店'
                }, {
                    value: 2,
                    label: '检测报告生成后离店'
                }, {
                    value: 3,
                    label: '症状描述完成后离店'
                }, {
                    value: 4,
                    label: '面诊完成后离店'
                }, {
                    value: 5,
                    label: '处方单开具后未支付离店'
                }, {
                    value: 6,
                    label: '处方单支付后离店'
                }, {
                    value: 7,
                    label: '服务实施完成后离店'
                },],
                storeList: [],
            },

            currentID: 0,
            currentUserID: 0,
            //查看解决方案
            dialogLookSolutionVisible: false,
            //查看报告
            dialogLookReportDetailVisible: false,
            isDialogContrastReportVisible: false,   //对比检测
            contrastEntryRecordInfo: {
                startEntryRecordId: 0,  //开始入店记录
                startEntryRecordTime: '',  //开始入店时间
                endEntryRecordId: 0,  //结束入店记录
                endEntryRecordTime: '',   //结束入店时间
            },
            isTimeDialogVisible: false,  //对比时间
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.handlePermission()
        if (this.permission.selectStoreList) {
            this.getStoreList()
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getStoreList() {
            try {
                let data = await storeManager.storeList()
                if (data.code == 200) {
                    this.searchFrom.storeList = data.data
                }
            } catch (error) {
                //
            }
        },

        solutionHandle(row) {
            this.currentID = row.id
            this.dialogLookSolutionVisible = true
        },
        async updateSeekBeautyLogNewInfoHandle(row) {
            try {
                let data = await beautyManager.updateSeekBeautyLogNewInfo({
                    id: row.id,
                    isCase: row.isCase == 1 ? 0 : 1
                })
                if (data.code == 200) {
                    this.$message.success(data.message)
                    this.refresCurrentPagehData()
                }
            } catch (error) {
                //
            }
        },
        getReportDetailHandle(row) {
            this.contrastEntryRecordInfo.startEntryRecordId = row.id
            this.currentID = row.id
            this.currentUserID = row.userId
            this.dialogLookReportDetailVisible = true
        },
        // 选择时间成功
        changeContrastTimeSuccess(time_obj) {
            this.isDialogContrastReportVisible = true
            this.contrastEntryRecordInfo = time_obj
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
