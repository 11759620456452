<template>
    <div class="container">
        <div class="page-container" :loading="showLoading">
            <el-form label-width="77px" class="ele-form-search">
                <el-row :gutter="15" style="padding-bottom: 20px;">
                    <!-- 客户 -->
                    <template v-if="dataInfo.seekBeautyLogUserInfo">
                        <el-col>
                            <div class="fr-s-c page-nav-title">
                                <h3>客户信息</h3>
                            </div>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="姓名:">
                                {{ dataInfo.seekBeautyLogUserInfo.nickName || '--' }}
                            </el-form-item>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="姓名:">
                                {{ dataInfo.seekBeautyLogUserInfo.sex == 1 ? '男' : dataInfo.seekBeautyLogUserInfo.sex
                                    == 2 ? '女' : '未知' }}
                            </el-form-item>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="手机号:">
                                {{ dataInfo.seekBeautyLogUserInfo.phone }}
                            </el-form-item>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="检测时间:">
                                {{ dataInfo.seekBeautyLogUserInfo.checkTime | formatDate }}
                            </el-form-item>
                        </el-col>
                    </template>
                    <!-- 美容师 -->
                    <template
                        v-if="dataInfo.seekBeautyLogUserInfo && dataInfo.seekBeautyLogUserInfo.beauticianNickName">
                        <el-col>
                            <div class="fr-s-c page-nav-title">
                                <h3>美容师</h3>
                            </div>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="姓名:">
                                {{ dataInfo.seekBeautyLogUserInfo.beauticianNickName }}
                            </el-form-item>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="手机号:">
                                {{ dataInfo.seekBeautyLogUserInfo.beauticianPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="门店名称:">
                                {{ dataInfo.seekBeautyLogUserInfo.storeName }}
                            </el-form-item>
                        </el-col>
                    </template>
                    <!-- 问卷 -->
                    <template v-if="dataInfo.byBeautyDiaryIdAndUserId && dataInfo.byBeautyDiaryIdAndUserId.length">
                        <el-col>
                            <div class="fr-s-c page-nav-title">
                                <h3>问卷信息</h3>
                            </div>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }" v-for="item, index in dataInfo.byBeautyDiaryIdAndUserId"
                            :key="index">
                            <el-form-item label-width="0px">
                                {{ (index + 1) + '、' + item.questionName + ':' + item.answer }}
                            </el-form-item>
                        </el-col>
                    </template>
                    <!-- 解决方案 -->
                    <template v-if="dataInfo.diagnosticResultsInfoVO">
                        <el-col>
                            <div class="fr-s-c page-nav-title">
                                <h3>解决方案</h3>
                            </div>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="治疗内容:">
                                {{ dataInfo.diagnosticResultsInfoVO.treatmentContent }}
                            </el-form-item>
                        </el-col>
                        <el-col v-bind="{ lg: 16, md: 12 }">
                            <el-form-item label="面诊结果:">
                                {{ dataInfo.diagnosticResultsInfoVO.interviewResults }}
                            </el-form-item>
                        </el-col>
                        <el-col class="page-treatment-options">
                            <el-col v-bind="{ lg: 7, md: 12 }">
                                <el-form-item label="治疗项目:">
                                    {{ dataInfo.diagnosticResultsInfoVO.treatmentTtemsName }}
                                </el-form-item>
                            </el-col>
                            <el-col v-bind="{ lg: 12, md: 12 }">
                                <el-form-item label="治疗原则:">
                                    {{ dataInfo.diagnosticResultsInfoVO.treatmentPrinciples }}
                                </el-form-item>
                            </el-col>
                            <el-col v-bind="{ lg: 5, md: 12 }">
                                <el-form-item label="建议次数:">
                                    {{ dataInfo.diagnosticResultsInfoVO.suggestionFrequency }}次
                                </el-form-item>
                            </el-col>
                            <el-col>
                                治疗方案
                            </el-col>
                            <el-col style="white-space: break-spaces;">
                                {{ dataInfo.diagnosticResultsInfoVO.treatmentDescription }}
                            </el-col>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }">
                            <el-form-item label="备注:">
                                {{ dataInfo.diagnosticResultsInfoVO.suggestedRemarks }}
                            </el-form-item>
                        </el-col>
                    </template>
                    <!-- 院线 -->
                    <template v-if="dataInfo.y && dataInfo.y.length">
                        <el-col class="page-goods-title fr-s-c">
                            <span>院线</span>
                        </el-col>
                        <el-col v-for="item in dataInfo.y" :key="'y' + item.goodsId" style="padding: 12px;"
                            class="fr-b-c">
                            <el-image style="width: 80px; height: 80px" :src="item.goodsImage" lazy></el-image>
                            <div class="m-l-20 flex-1">
                                <div class="text-line-1">{{ item.goodsName }}</div>
                                <div
                                    v-if="item.goodsComponentRelationDOList && item.goodsComponentRelationDOList.length">
                                    <el-popover placement="bottom" title="商品成分" width="200" trigger="click">
                                        <template>
                                            <template
                                                v-if="item.goodsComponentRelationDOList && item.goodsComponentRelationDOList.length">
                                                <div class="fr-b-c"
                                                    v-for="item, index in item.goodsComponentRelationDOList"
                                                    :key="'j' + item.goodsId + 'component' + index">
                                                    {{ item.componentName }} {{ item.componentContent + 'mg' }}
                                                </div>
                                            </template>
                                            <template v-else>暂无</template>
                                        </template>
                                        <el-button type="text" slot="reference">查看成分</el-button>
                                    </el-popover>
                                </div>
                                <div v-else style="padding: 10px;">

                                </div>
                                <div class="fr-b-c">
                                    <span class="color-90">¥{{ item.currentPrice }}</span>
                                    <span class="color-90" style="font-size: 12px;">x{{ item.goodsNumber }}</span>
                                </div>
                            </div>
                        </el-col>
                    </template>
                    <!-- 居家 -->
                    <template v-if="dataInfo.j && dataInfo.j.length">
                        <el-col class="page-goods-title fr-s-c">
                            <span>居家</span>
                        </el-col>
                        <el-col v-for="item in dataInfo.j" :key="'j' + item.goodsId" style="padding: 12px;"
                            class="fr-b-c">
                            <el-image style="width: 80px; height: 80px" :src="item.goodsImage" lazy></el-image>
                            <div class="m-l-20 flex-1">
                                <div class="text-line-1">{{ item.goodsName }}</div>
                                <div
                                    v-if="item.goodsComponentRelationDOList && item.goodsComponentRelationDOList.length">
                                    <el-popover placement="bottom" title="商品成分" width="200" trigger="click">
                                        <template>
                                            <template
                                                v-if="item.goodsComponentRelationDOList && item.goodsComponentRelationDOList.length">
                                                <div class="fr-b-c"
                                                    v-for="item, index in item.goodsComponentRelationDOList"
                                                    :key="'j' + item.goodsId + 'component' + index">
                                                    {{ item.componentName }} {{ item.componentContent + 'mg' }}
                                                </div>
                                            </template>
                                            <template v-else>暂无</template>
                                        </template>
                                        <el-button type="text" slot="reference">查看成分</el-button>
                                    </el-popover>
                                </div>
                                <div v-else style="padding: 10px;">

                                </div>
                                <div class="fr-b-c">
                                    <span class="color-90">¥{{ item.currentPrice }}</span>
                                    <span class="color-90" style="font-size: 12px;">x{{ item.goodsNumber }}</span>
                                </div>
                            </div>
                        </el-col>
                    </template>
                    <!-- 服务内容 -->
                    <template v-if="dataInfo.service && dataInfo.service.length">
                        <el-col>
                            <div class="fr-s-c page-nav-title">
                                <h3>服务内容</h3>
                            </div>
                        </el-col>
                        <el-col v-bind="{ lg: 8, md: 12 }" v-for="item, index in dataInfo.service"
                            :key="'service' + index">
                            <el-col v-bind="{ lg: 8, md: 12 }">
                                <el-form-item label="服务内容:">
                                    {{ item.serviceContent }}
                                </el-form-item>
                            </el-col>
                            <el-col v-bind="{ lg: 8, md: 12 }">
                                <el-form-item label="服务时间">
                                    {{ item.serviceTime | formatDate }}
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </template>
                    <!-- 消息内容 -->
                    <template v-if="dataInfo.detail && dataInfo.detail.length">
                        <el-col>
                            <div class="fr-s-c page-nav-title">
                                <h3>消息内容</h3>
                            </div>
                        </el-col>
                        <el-col v-for="item, index in dataInfo.detail" :key="'message' + index">
                            <div class="fr-s-s">
                                <el-image class="page-user-icon" lazy :src="item.headImg"></el-image>
                                <div class="flex-1 m-l-10">
                                    <div class="page-user-name-type fr-s-c">
                                        <div class="page-user-type fr-c" :style="{
                                            'background-color': item.sendUserType == 1 ? '#FFBC3A' :
                                                item.sendUserType
                                                    == 2 ? '#D197FF' : item.sendUserType == 3 ? '#50B1B2' : ''
                                        }"> {{
                                            item.sendUserType == 1 ? '用户' :
                                                item.sendUserType
                                                    == 2 ? '美容师' : item.sendUserType == 3 ? '专家' : '' }}</div>
                                        <span>{{ item.sendUserName }}</span>
                                        <span>{{ item.sendTime | formatDate }}</span>
                                    </div>
                                    <div class="fr-s-s">
                                        <div class="page-user-send-content">
                                            <p>{{ item.content }}</p>
                                            <div class="m-t-10"
                                                v-if="item.seekBeautyLogMessageGalleryInfoDOList && item.seekBeautyLogMessageGalleryInfoDOList.length">
                                                <el-image
                                                    v-for="imageItem, index in item.seekBeautyLogMessageGalleryInfoDOList"
                                                    :key="'imageAtait' + imageItem.galleryId"
                                                    style="width: 84px; height: 84px;margin: 0px 10px 10px 0px;"
                                                    :src="imageItem.thumbImg"
                                                    :preview-src-list="item.seekBeautyLogMessageGalleryInfoDOList.map(obj => obj.bigImg)"
                                                    :initial-index="index">

                                                </el-image>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </template>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import beautyManager from '@/request/beautyManager.js'
import { getList } from '@/tools/getList.js'
export default {
    mixins: [getList],
    props: ['id'],
    // import引入的组件需要注入到对象中才能使用
    components: {

    },
    data() {
        // 这里存放数据
        return {
            that: this,
            dataInfo: {

            },
            showLoading: false,
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getSolution()
    },
    filters: {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getSolution() {
            try {
                this.showLoading = true
                let data = await beautyManager.getSeekBeautyLogDetail({ seekBeautyId: this.id })
                if (data.code == 200) {
                    //
                    this.dataInfo = data.data

                }
            } catch (error) {
                //
            } finally {
                this.showLoading = false
            }
        },
        lookGoodsComHandle(row) {

        },

    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped lang="scss">
.page-content-item {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;

    .title {
        color: #323232;
    }

    .content {
        color: #999999;
    }
}


.el-form-item {
    margin-bottom: 10px;
}

.page-nav-title {
    height: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-left: 4px #50B1B2 solid;
}

.page-nav-title h3 {
    margin-left: 10px;
}

.page-treatment-options {
    background-color: #F7FAFA;
    border: 1px #50B1B2 solid;
    padding: 16px;
    border-radius: 8px;
}

.page-goods-title {
    height: 28px;
    background-color: #FAFAFA;
    color: #50B1B2;
    padding-left: 20px;
    font-size: 14px;
    margin-top: 10px;
}

.page-user-icon {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

.page-user-type {
    height: 21px;
    padding: 0px 4px;
    font-size: 12px;
    color: white;
    border-radius: 4px;
}

.page-user-name-type span {
    color: #909090;
    font-size: 14px;
    margin-left: 12px;
}

.page-user-send-content {
    background-color: #F0F2F2;
    border-radius: 2px 12px 12px 12px;
    padding: 12px;
    margin: 12px 12px 12px 0px;
}
</style>
