var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-container", attrs: { loading: _vm.showLoading } },
      [
        _c(
          "el-form",
          { staticClass: "ele-form-search", attrs: { "label-width": "77px" } },
          [
            _c(
              "el-row",
              {
                staticStyle: { "padding-bottom": "20px" },
                attrs: { gutter: 15 },
              },
              [
                _vm.dataInfo.seekBeautyLogUserInfo
                  ? [
                      _c("el-col", [
                        _c("div", { staticClass: "fr-s-c page-nav-title" }, [
                          _c("h3", [_vm._v("客户信息")]),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c("el-form-item", { attrs: { label: "姓名:" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInfo.seekBeautyLogUserInfo.nickName ||
                                    "--"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c("el-form-item", { attrs: { label: "姓名:" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInfo.seekBeautyLogUserInfo.sex == 1
                                    ? "男"
                                    : _vm.dataInfo.seekBeautyLogUserInfo.sex ==
                                      2
                                    ? "女"
                                    : "未知"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c("el-form-item", { attrs: { label: "手机号:" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInfo.seekBeautyLogUserInfo.phone
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检测时间:" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      _vm.dataInfo.seekBeautyLogUserInfo
                                        .checkTime
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.dataInfo.seekBeautyLogUserInfo &&
                _vm.dataInfo.seekBeautyLogUserInfo.beauticianNickName
                  ? [
                      _c("el-col", [
                        _c("div", { staticClass: "fr-s-c page-nav-title" }, [
                          _c("h3", [_vm._v("美容师")]),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c("el-form-item", { attrs: { label: "姓名:" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInfo.seekBeautyLogUserInfo
                                    .beauticianNickName
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c("el-form-item", { attrs: { label: "手机号:" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInfo.seekBeautyLogUserInfo
                                    .beauticianPhone
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "门店名称:" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dataInfo.seekBeautyLogUserInfo.storeName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.dataInfo.byBeautyDiaryIdAndUserId &&
                _vm.dataInfo.byBeautyDiaryIdAndUserId.length
                  ? [
                      _c("el-col", [
                        _c("div", { staticClass: "fr-s-c page-nav-title" }, [
                          _c("h3", [_vm._v("问卷信息")]),
                        ]),
                      ]),
                      _vm._l(
                        _vm.dataInfo.byBeautyDiaryIdAndUserId,
                        function (item, index) {
                          return _c(
                            "el-col",
                            _vm._b(
                              { key: index },
                              "el-col",
                              { lg: 8, md: 12 },
                              false
                            ),
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        index +
                                          1 +
                                          "、" +
                                          item.questionName +
                                          ":" +
                                          item.answer
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ]
                  : _vm._e(),
                _vm.dataInfo.diagnosticResultsInfoVO
                  ? [
                      _c("el-col", [
                        _c("div", { staticClass: "fr-s-c page-nav-title" }, [
                          _c("h3", [_vm._v("解决方案")]),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "治疗内容:" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dataInfo.diagnosticResultsInfoVO
                                      .treatmentContent
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 16, md: 12 }, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "面诊结果:" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dataInfo.diagnosticResultsInfoVO
                                      .interviewResults
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "page-treatment-options" },
                        [
                          _c(
                            "el-col",
                            _vm._b({}, "el-col", { lg: 7, md: 12 }, false),
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "治疗项目:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dataInfo.diagnosticResultsInfoVO
                                          .treatmentTtemsName
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            _vm._b({}, "el-col", { lg: 12, md: 12 }, false),
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "治疗原则:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dataInfo.diagnosticResultsInfoVO
                                          .treatmentPrinciples
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            _vm._b({}, "el-col", { lg: 5, md: 12 }, false),
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "建议次数:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dataInfo.diagnosticResultsInfoVO
                                          .suggestionFrequency
                                      ) +
                                      "次 "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("el-col", [_vm._v(" 治疗方案 ")]),
                          _c(
                            "el-col",
                            { staticStyle: { "white-space": "break-spaces" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dataInfo.diagnosticResultsInfoVO
                                      .treatmentDescription
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                        [
                          _c("el-form-item", { attrs: { label: "备注:" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.dataInfo.diagnosticResultsInfoVO
                                    .suggestedRemarks
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.dataInfo.y && _vm.dataInfo.y.length
                  ? [
                      _c("el-col", { staticClass: "page-goods-title fr-s-c" }, [
                        _c("span", [_vm._v("院线")]),
                      ]),
                      _vm._l(_vm.dataInfo.y, function (item) {
                        return _c(
                          "el-col",
                          {
                            key: "y" + item.goodsId,
                            staticClass: "fr-b-c",
                            staticStyle: { padding: "12px" },
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "80px", height: "80px" },
                              attrs: { src: item.goodsImage, lazy: "" },
                            }),
                            _c("div", { staticClass: "m-l-20 flex-1" }, [
                              _c("div", { staticClass: "text-line-1" }, [
                                _vm._v(_vm._s(item.goodsName)),
                              ]),
                              item.goodsComponentRelationDOList &&
                              item.goodsComponentRelationDOList.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            title: "商品成分",
                                            width: "200",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          [
                                            item.goodsComponentRelationDOList &&
                                            item.goodsComponentRelationDOList
                                              .length
                                              ? _vm._l(
                                                  item.goodsComponentRelationDOList,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key:
                                                          "j" +
                                                          item.goodsId +
                                                          "component" +
                                                          index,
                                                        staticClass: "fr-b-c",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.componentName
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.componentContent +
                                                                "mg"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              : [_vm._v("暂无")],
                                          ],
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "text",
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v("查看成分")]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", {
                                    staticStyle: { padding: "10px" },
                                  }),
                              _c("div", { staticClass: "fr-b-c" }, [
                                _c("span", { staticClass: "color-90" }, [
                                  _vm._v("¥" + _vm._s(item.currentPrice)),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-90",
                                    staticStyle: { "font-size": "12px" },
                                  },
                                  [_vm._v("x" + _vm._s(item.goodsNumber))]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                    ]
                  : _vm._e(),
                _vm.dataInfo.j && _vm.dataInfo.j.length
                  ? [
                      _c("el-col", { staticClass: "page-goods-title fr-s-c" }, [
                        _c("span", [_vm._v("居家")]),
                      ]),
                      _vm._l(_vm.dataInfo.j, function (item) {
                        return _c(
                          "el-col",
                          {
                            key: "j" + item.goodsId,
                            staticClass: "fr-b-c",
                            staticStyle: { padding: "12px" },
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "80px", height: "80px" },
                              attrs: { src: item.goodsImage, lazy: "" },
                            }),
                            _c("div", { staticClass: "m-l-20 flex-1" }, [
                              _c("div", { staticClass: "text-line-1" }, [
                                _vm._v(_vm._s(item.goodsName)),
                              ]),
                              item.goodsComponentRelationDOList &&
                              item.goodsComponentRelationDOList.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            title: "商品成分",
                                            width: "200",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          [
                                            item.goodsComponentRelationDOList &&
                                            item.goodsComponentRelationDOList
                                              .length
                                              ? _vm._l(
                                                  item.goodsComponentRelationDOList,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key:
                                                          "j" +
                                                          item.goodsId +
                                                          "component" +
                                                          index,
                                                        staticClass: "fr-b-c",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.componentName
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.componentContent +
                                                                "mg"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              : [_vm._v("暂无")],
                                          ],
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "text",
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v("查看成分")]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", {
                                    staticStyle: { padding: "10px" },
                                  }),
                              _c("div", { staticClass: "fr-b-c" }, [
                                _c("span", { staticClass: "color-90" }, [
                                  _vm._v("¥" + _vm._s(item.currentPrice)),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-90",
                                    staticStyle: { "font-size": "12px" },
                                  },
                                  [_vm._v("x" + _vm._s(item.goodsNumber))]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                    ]
                  : _vm._e(),
                _vm.dataInfo.service && _vm.dataInfo.service.length
                  ? [
                      _c("el-col", [
                        _c("div", { staticClass: "fr-s-c page-nav-title" }, [
                          _c("h3", [_vm._v("服务内容")]),
                        ]),
                      ]),
                      _vm._l(_vm.dataInfo.service, function (item, index) {
                        return _c(
                          "el-col",
                          _vm._b(
                            { key: "service" + index },
                            "el-col",
                            { lg: 8, md: 12 },
                            false
                          ),
                          [
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "服务内容:" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.serviceContent) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", { lg: 8, md: 12 }, false),
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "服务时间" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(item.serviceTime)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ]
                  : _vm._e(),
                _vm.dataInfo.detail && _vm.dataInfo.detail.length
                  ? [
                      _c("el-col", [
                        _c("div", { staticClass: "fr-s-c page-nav-title" }, [
                          _c("h3", [_vm._v("消息内容")]),
                        ]),
                      ]),
                      _vm._l(_vm.dataInfo.detail, function (item, index) {
                        return _c("el-col", { key: "message" + index }, [
                          _c(
                            "div",
                            { staticClass: "fr-s-s" },
                            [
                              _c("el-image", {
                                staticClass: "page-user-icon",
                                attrs: { lazy: "", src: item.headImg },
                              }),
                              _c("div", { staticClass: "flex-1 m-l-10" }, [
                                _c(
                                  "div",
                                  { staticClass: "page-user-name-type fr-s-c" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "page-user-type fr-c",
                                        style: {
                                          "background-color":
                                            item.sendUserType == 1
                                              ? "#FFBC3A"
                                              : item.sendUserType == 2
                                              ? "#D197FF"
                                              : item.sendUserType == 3
                                              ? "#50B1B2"
                                              : "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.sendUserType == 1
                                                ? "用户"
                                                : item.sendUserType == 2
                                                ? "美容师"
                                                : item.sendUserType == 3
                                                ? "专家"
                                                : ""
                                            )
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(item.sendUserName)),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(item.sendTime)
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "fr-s-s" }, [
                                  _c(
                                    "div",
                                    { staticClass: "page-user-send-content" },
                                    [
                                      _c("p", [_vm._v(_vm._s(item.content))]),
                                      item.seekBeautyLogMessageGalleryInfoDOList &&
                                      item.seekBeautyLogMessageGalleryInfoDOList
                                        .length
                                        ? _c(
                                            "div",
                                            { staticClass: "m-t-10" },
                                            _vm._l(
                                              item.seekBeautyLogMessageGalleryInfoDOList,
                                              function (imageItem, index) {
                                                return _c("el-image", {
                                                  key:
                                                    "imageAtait" +
                                                    imageItem.galleryId,
                                                  staticStyle: {
                                                    width: "84px",
                                                    height: "84px",
                                                    margin: "0px 10px 10px 0px",
                                                  },
                                                  attrs: {
                                                    src: imageItem.thumbImg,
                                                    "preview-src-list":
                                                      item.seekBeautyLogMessageGalleryInfoDOList.map(
                                                        (obj) => obj.bigImg
                                                      ),
                                                    "initial-index": index,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ])
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }