<template>
  <div class="change-time-dialog-wrap-part-box">
    <el-dialog custom-class="change-time-dialog-part-box" width="800px" title="请选择" center :visible="is_time_dialog_visible" :close-on-click-modal="false" @close="closeChangeTimeDialog" :modal-append-to-body="false">
      <div class="w-1 dialog-content-part-wrap-box fr-b-s text-no-select">
        <!-- 开始时间 -->
        <div class="dialog-chang-date-item-box fl-s-c" :class="{'active':addOrEditModel.startEntryRecordId}">
          <div class="w-1 date-item-not-select-picker-part-box" v-if="!addOrEditModel.startEntryRecordId"></div>
          <div class="w-1 date-item-selected-box fl-s-c" v-else>
            <div class="w-1 chang-date-popover-btn fr-s-c text-16 color-main">{{$util.formatTime(addOrEditModel.startReportTime,'yyyy-mm-dd hh:M')}}</div>
            <div class="w-1 fr-c date-selected-image-box">
              <el-image class="iamges-origin" fit="fit" :src="addOrEditModel.startImgDaylight"></el-image>
            </div>
          </div>
        </div>
        <!-- 结束时间 -->
        <div class="dialog-chang-date-item-box fl-s-c" :class="{'active':addOrEditModel.endEntryRecordId}">
          <div class="w-1 date-item-not-select-picker-part-box" v-if="!addOrEditModel.endEntryRecordId">
            <el-popover popper-class="dialog-chang-date-popover" placement="bottom" width="300" trigger="click" v-model="isChangEndDatePopper">
              <div class="w-1 h-1 fr-s-c chang-date-popover-btn" slot="reference">
                <img class="iamges-date" :src="contrast_pop_date" />
                <span class="text-16 color-32">结束日期</span>
              </div>
              <el-scrollbar class="w-1 h-1 chang-date-popover-scrollbar fr-s-s">
                <div class="w-1 fr-s-c chang-date-popover-item" :class="{'selected':addOrEditModel.endEntryRecordId==item.id}" @click="clickSelectEndTimeEntryRecord(item)" v-for="(item,index) in endEntryRecordList" :key="index">
                  <span>{{$util.formatTime(item.reportTime,'yyyy-mm-dd hh:M')}}</span>
                </div>
              </el-scrollbar>
            </el-popover>
          </div>
          <div class="w-1 date-item-selected-box fl-s-c" v-else>
            <div class="w-1">
              <el-popover popper-class="dialog-chang-date-popover" placement="bottom" width="300" trigger="click" v-model="isChangEndDatePopper">
                <div class="w-1 h-1 fr-s-c chang-date-popover-btn" slot="reference">
                  <img class="iamges-date" :src="contrast_pop_date" />
                  <span class="text-16 color-main">{{$util.formatTime(addOrEditModel.endReportTime,'yyyy-mm-dd hh:M')}}</span>
                </div>
                <el-scrollbar class="w-1 h-1 chang-date-popover-scrollbar fr-s-s">
                  <div class="w-1 fr-s-c chang-date-popover-item" :class="{'selected':addOrEditModel.endEntryRecordId==item.id}" @click="clickSelectEndTimeEntryRecord(item)" v-for="(item,index) in endEntryRecordList" :key="index">
                    <span>{{$util.formatTime(item.reportTime,'yyyy-mm-dd hh:M')}}</span>
                  </div>
                </el-scrollbar>
              </el-popover>
            </div>
            <div class="w-1 fr-c date-selected-image-box">
              <el-image class="iamges-origin" fit="fit" :src="addOrEditModel.endImgDaylight"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer fr-e-c">
        <el-button plain round @click="closeChangeTimeDialog">取消</el-button>
        <el-button type="primary" round @click="clickConfirmChangeTime">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    is_time_dialog_visible: {   //是否显示
      type: Boolean,
      default: false
    },
    seek_beauty_id: {   //问诊记录ID
      type: [Number, String],
      default: 1
    },
    start_report_time: {
      type: [Number, String],
      default: 0
    },
    start_img_daylight: {
      type: [Number, String],
      default: ''
    },
    contrast_time_data: {
      type: Array,
      default: () => { return []; }
    }
  },
  data () {
    // 这里存放数据
    return {
      contrast_pop_date: require('@/assets/img/testReport/inspectReport_contrast_pop_date_icon.png'), //对比弹窗日期-图标
      addOrEditModel: {
        startEntryRecordId: this.seek_beauty_id,  //开始求美日志
        startReportTime: this.start_report_time,  //开始报告时间
        startImgDaylight: this.start_img_daylight,  //开始报告-原图
        endEntryRecordId: 0,  //结束报告记录
        endReportTime: '', //结束报告时间
        endImgDaylight: '',  //结束报告-原图
      },

      isChangEndDatePopper: false,
      endEntryRecordList: this.contrast_time_data,
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // this.getPageEntryRecordTimeData();   //获取用户入店记录时间信息
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 获取用户入店记录时间信息
    async getPageEntryRecordTimeData () {
      this.endEntryRecordList = this.contrast_time_data.filter(item => item.id != this.addOrEditModel.startEntryRecordId);  //结束时间-记录列表
    },

    // 选择结束入店时间
    clickSelectEndTimeEntryRecord (end_time_item) {
      // console.log('选择结束入店时间', end_time_item)
      this.addOrEditModel.endEntryRecordId = end_time_item.id
      this.addOrEditModel.endImgDaylight = end_time_item.imgDaylight
      this.addOrEditModel.endReportTime = end_time_item.reportTime
      this.isChangEndDatePopper = false
    },
    // 点击确认选择时间
    clickConfirmChangeTime () {
      let { startEntryRecordId, endEntryRecordId } = this.addOrEditModel
      if (!startEntryRecordId) return this.$message.error('请选择开始时间！');
      if (!endEntryRecordId) return this.$message.error('请选择结束时间！');
      if (endEntryRecordId == startEntryRecordId) return this.$message.error('同一条记录的无法对比');

      this.closeChangeTimeDialog();  //关闭弹窗
      this.$emit('changeContrastTimeSuccess', this.addOrEditModel)
    },



    // 关闭弹窗
    closeChangeTimeDialog () {
      this.$emit("update:is_time_dialog_visible", false);
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped lang="scss">
.change-time-dialog-wrap-part-box {
  .dialog-content-part-wrap-box {
    .dialog-chang-date-item-box {
      width: 300px;
      min-height: 46px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #e5e5e5;
     
      &.active {
        height: 260px;
        border-color: #50b1b2;
      }
      .chang-date-popover-btn {
        height: 46px;
        padding: 12px 20px;
        box-sizing: border-box;
        cursor: pointer;
        .iamges-date {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
      }
      .date-item-selected-box {
        // padding: 12px 12px;
        // box-sizing: border-box;
        .date-selected-image-box {
          margin-top: 10px;
          .iamges-origin {
            width: 138px;
            height: 171px;
            border-radius: 8px 8px 8px 8px;
          }
        }
      }
    }
  }

  ::v-deep .el-dialog__wrapper {
    position: fixed !important;

    .el-dialog {
      min-height: auto;
      border-radius: 8px;
      margin: 0 !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      .el-dialog__header {
        border: none;
        border-radius: 8px;
        padding: 20px 30px 10px;
        .el-dialog__headerbtn {
          right: 30px;
        }
      }

      .el-dialog__body {
        min-height: 260px;
        padding: 20px 60px 10px;
      }
      .el-dialog__footer {
        padding: 20px 60px 60px;
        .el-button {
          font-size: 16px;
          padding: 8px 44px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
// .el-popover{
//   overflow: hidden;
// }


.dialog-chang-date-popover {
  max-height: 274px;
  padding: 0px;
  overflow-y: scroll;
  .chang-date-popover-scrollbar {
    .el-scrollbar__wrap {
      width: 100%;
    }
    .el-scrollbar__view {
      width: 100%;
      padding: 6px 0px;
      .chang-date-popover-item {
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 14px;
        color: #606266;
        &:hover {
          background-color: #f5f7fa;
        }
        &.selected {
          // background-color: #f5f7fa;
          color: #50b1b2;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
